//import { createValue } from "lib/entity/calc"
//import { $lineage } from "lib/entity/symbols"
import Type from "lib/entity/type"
import Data from "lib/entity/data"

const plus_price_int = (a1, a2) => ({
    ...a1,
    value: {
        ...(a1.value ?? {}),
        amount: a1.value.amount + a2.value * 10 ** Data.get(a1, "value.amount.decimals") ?? 0,
    },
})
const plus_unitPrice_int = plus_price_int

const plus_int_price = (a1, a2, t1, t2) => plus_price_int(a2, a1, t2)
const plus_int_unitPrice = plus_int_price

const plus_price_number = (a1, a2) => {
    const d1 = Data.get(a1, "value.amount.decimals") ?? 0
    const d2 = Data.get(a2, "value.decimals") ?? 0
    const amount =
        d2 > d1
            ? a1.value.amount * 10 ** (d2 - d1) + a2.value
            : a1.value.amount + a2.value * 10 ** (d1 - d2)
    const decimals = d2 >= d1 ? d2 : d1
    //console.log(amount, a1, a2, t1, t2)
    return {
        ...a1,
        value: {
            ...(a1.value ?? {}),
            amount,
            _e: {
                ...(a1.value?._e ?? {}),
                amount: {
                    ...(a1.value?._e?.amount ?? {}),
                    decimals,
                },
            },
        },
    }
}
const plus_unitPrice_number = plus_price_number

const plus_number_price = (a1, a2, t1, t2) => plus_price_number(a2, a1, t2, t1)
const plus_number_unitPrice = plus_number_price

const plus_price_price = (a1, a2) => {
    const d1 = Data.get(a1, "value.amount.decimals") ?? 0
    const d2 = Data.get(a2, "value.amount.decimals") ?? 0
    const amount =
        d2 > d1
            ? a1.value.amount * 10 ** (d2 - d1) + a2.value.amount
            : a1.value.amount + a2.value.amount * 10 ** (d1 - d2)
    const decimals = d2 >= d1 ? d2 : d1
    //console.log(amount, a1, a2, t1, t2)
    return {
        ...a1,
        value: {
            ...(a1.value ?? {}),
            amount,
            _e: {
                ...(a1.value?._e ?? {}),
                amount: {
                    ...(a1.value?._e?.amount ?? {}),
                    decimals,
                },
            },
        },
    }
}
const plus_unitPrice_unitPrice = plus_price_price

const times_price_int = (a1, a2) => ({
    ...a1,
    value: {
        ...(a1.value ?? {}),
        amount: a1.value.amount * a2.value,
    },
})
const times_int_price = (a1, a2) => times_price_int(a2, a1)
const times_unitPrice_int = times_price_int
const times_int_unitPrice = times_int_price

const times_price_number = (a1, a2) => {
    //console.log(a1, a2, t1, t2)
    const d1 = Data.get(a1, "value.amount.decimals") ?? 0
    const d2 = Data.get(a2, "value.decimals") ?? 0
    const amount = a1.value.amount * a2.value
    const decimals = d1 + d2
    //console.log("PRICE_NUMBER", d1, d2, t2, decimals)
    return {
        ...a1,
        value: {
            ...(a1.value ?? {}),
            amount,
            _e: {
                ...(a1.value?._e ?? {}),
                amount: {
                    ...(a1.value?._e?.amount ?? {}),
                    decimals,
                },
            },
        },
    }
}
const times_number_price = (a1, a2, t1, t2) => times_price_number(a2, a1, t2, t1)
const times_unitPrice_number = times_price_number
const times_number_unitPrice = times_number_price
const times_weight_number = times_price_number
const times_number_weight = times_number_price
const times_number_estWeight = times_number_price

const times_price_price = (a1, a2, t1) =>
    times_price_number(
        a1,
        Data.getValue(a2, "value.amount"),
        t1,
        Type.getKeyType("value.amount", a2)
    )
const times_unitPrice_unitPrice = times_price_price

const times_price_weight = times_price_price
const times_weight_unitPrice = (a1, a2, t1) => {
    // 2(f*unit=g) * 5 lei/(kg===f*unit)
    const { amount, currency } = a2.value
    const price = Data.createValue({ amount, currency }, "price")
    const priceType = Type.getKeyType("value", price)

    const initWeight = Data.getValue(a1, "value.amount")
    const weight = {
        ...initWeight,
        value: (initWeight.value * (a1.value?.um?.factor ?? 1)) / (a2.value?.um?.factor ?? 1),
    }
    const weightType = Type.getKeyType("value.amount", a1, t1)
    //console.log(price, weight)
    return times_price_number(price, weight, priceType, weightType)
}
const times_unitPrice_weight = (a1, a2, t1, t2) => times_weight_unitPrice(a2, a1, t2, t1)

const times_weight_price = (a1, a2, t1, t2) => times_price_price(a2, a1, t2, t1)
const times_unitPrice_estWeight = times_unitPrice_weight
const times_estWeight_unitPrice = times_weight_unitPrice

/*
const priceAdd = (arg1, arg2, at1, at2) => {
    let a1 = arg1
    let a2 = arg2
    let t1 = at1
    let t2 = at2
    if (!Type.is(at1, "price")) {
        a1 = arg2
        a2 = arg1
        t1 = at2
        t2 = at1
    }
    const d1 = Data.get(a1, "value.amount.decimals", { parentType: t1 }) ?? 0
    if (Type.is(t2, "int")) {
        //console.log(a1.value.amount + a2.value * 10 ** d1, a1, a2, t1, t2)
        return {
            ...a1,
            value: {
                ...(a1.value ?? {}),
                amount: a1.value.amount + a2.value * 10 ** d1,
            },
        }
    }
    if (Type.is(t2, "number")) {
        const d2 = Data.get(a2, "value.decimals", { parentType: t2 }) ?? 0
        const amount =
            d2 > d1
                ? a1.value.amount * 10 ** (d2 - d1) + a2.value
                : a1.value.amount + a2.value * 10 ** (d1 - d2)
        const decimals = d2 >= d1 ? d2 : d1
        //console.log(amount, a1, a2, t1, t2)
        return {
            ...a1,
            value: {
                ...(a1.value ?? {}),
                amount,
                _e: {
                    ...(a1.value?._e ?? {}),
                    amount: {
                        ...(a1.value?._e?.amount ?? {}),
                        decimals,
                    },
                },
            },
        }
    }
    if (Type.is(t2, "price")) {
        const d2 = Data.get(a2, "value.amount.decimals", { parentType: t2 }) ?? 0
        const amount =
            d2 > d1
                ? a1.value.amount * 10 ** (d2 - d1) + a2.value.amount
                : a1.value.amount + a2.value.amount * 10 ** (d1 - d2)
        const decimals = d2 >= d1 ? d2 : d1
        //console.log(amount, a1, a2, t1, t2)
        return {
            ...a1,
            value: {
                ...(a1.value ?? {}),
                amount,
                _e: {
                    ...(a1.value?._e ?? {}),
                    amount: {
                        ...(a1.value?._e?.amount ?? {}),
                        decimals,
                    },
                },
            },
        }
    }
    console.log(a1, a2, t1, t2)
    return Data.createValue()
    /*
    if (!args || args.length === 0) return Data.createValue()
    const decimals = args.reduce((acc, arg) => {
        const t = Type.getKeyType("value", arg)
        if (t.decimals && t.decimals > acc) return t.decimals
        return acc
    }, 0)
    const ret = Data.createValue(
        {
            amount: args.reduce((acc, arg) => {
                const t = Type.getKeyType("value", arg)
                const d = decimals - (t?.decimals ?? 0)
                //console.log("ADD", acc, arg)
                return acc + (arg.value?.amount ?? 0) * 10 ** d
            }, 0),
            currency: args[0].value?.currency,
        },
        { is: "price", decimals }
    )
    //console.log("PLUS", args, args.slice(1), ret)
    return ret
*/
/*}
const priceTimes = (arg1, arg2, at1, at2) => {
    let a1 = arg1
    let a2 = arg2
    let t1 = at1
    let t2 = at2
    if (!Type.is(at1, "price")) {
        a1 = arg2
        a2 = arg1
        t1 = at2
        t2 = at1
    }
    //console.log("TIMES", args)
    const d1 = Data.get(a1, "value.amount.decimals", { parentType: t1 }) ?? 0
    if (Type.is(t2, "int")) {
        //console.log(a1.value.amount * a2.value, a1, a2, t1, t2)
        return {
            ...a1,
            value: {
                ...(a1.value ?? {}),
                amount: a1.value.amount * a2.value,
            },
        }
    }
    if (Type.is(t2, "number")) {
        const d2 = Data.get(a2, "value.decimals", { parentType: t2 }) ?? 0
        const amount = a1.value.amount * a2.value
        const decimals = d1 + d2
        //console.log(amount, a1, a2, t1, t2)
        return {
            ...a1,
            value: {
                ...(a1.value ?? {}),
                amount,
                _e: {
                    ...(a1.value?._e ?? {}),
                    amount: {
                        ...(a1.value?._e?.amount ?? {}),
                        decimals,
                    },
                },
            },
        }
    }
    if (Type.is(t2, "price")) {
        const d2 = Data.get(a2, "value.amount.decimals", { parentType: t2 }) ?? 0
        const amount = a1.value.amount * a2.value.amount
        const decimals = d1 + d2
        //console.log(amount, a1, a2, t1, t2)
        return {
            ...a1,
            value: {
                ...(a1.value ?? {}),
                amount,
                _e: {
                    ...(a1.value?._e ?? {}),
                    amount: {
                        ...(a1.value?._e?.amount ?? {}),
                        decimals,
                    },
                },
            },
        }
    }
    if (Type.is(t2, "weight")) {
        const d2 = Data.get(a2, "value.amount.decimals", { parentType: t2 }) ?? 0
        const amount = a1.value.amount * a2.value.amount
        const decimals = d1 + d2
        //console.log(amount, a1, a2, t1, t2)
        return {
            ...a1,
            value: {
                ...(a1.value ?? {}),
                amount,
                _e: {
                    ...(a1.value?._e ?? {}),
                    amount: {
                        ...(a1.value?._e?.amount ?? {}),
                        decimals,
                    },
                },
            },
        }
    }
    console.trace(a1, a2, t1, t2)
    return Data.createValue()
    /*
    if (!args || args.length === 0) return Data.createValue()
    const currency = args.reduce((acc, arg) => {
        if (acc) return acc
        const t = Type.getKeyType("value", arg)
        if (t?.[$lineage]?.includes("price")) return arg.value?.currency
        return null
    }, null)

    let d = 0
    let amount = args.reduce((acc, arg) => {
        //console.log("TIMES", acc, arg)
        if (typeof arg.value === "number") return acc * arg.value
        if (typeof arg.value === "string") {
            if (/^\d+$/.test(arg.value)) return acc * parseInt(arg.value)
            return acc
        }
        const t = Type.getKeyType("value", arg)
        if (Type.is(t, "weight")) {
            d++
            return acc * arg.value.amount * (arg.value.um?.factor ?? 1)
        }
        if (Type.is(t, "price")) {
            d++
            return (acc * arg.value.amount) / (arg.value.um?.factor ?? 1)
        }
        return acc
    }, 1)
    amount = amount / 100 ** (d - 1)
    //console.log(args, amount, d)
    const ret = Data.createValue(
        {
            amount,
            currency,
        },
        "price"
    )
    //console.log("TIMES", args, args.slice(1), ret)
    return ret
*/
//}
export default {
    plus_price_int,
    plus_unitPrice_int,
    plus_price_number,
    plus_unitPrice_number,
    plus_price_price,
    plus_unitPrice_unitPrice,
    plus_int_price,
    plus_int_unitPrice,
    plus_number_price,
    plus_number_unitPrice,
    times_price_int,
    times_unitPrice_int,
    times_price_number,
    times_weight_number,
    times_unitPrice_number,
    times_price_price,
    times_unitPrice_unitPrice,
    times_price_weight,
    times_unitPrice_weight,
    times_unitPrice_estWeight,
    times_int_price,
    times_int_unitPrice,
    times_number_price,
    times_number_unitPrice,
    times_number_weight,
    times_number_estWeight,
    times_weight_price,
    times_weight_unitPrice,
    times_estWeight_unitPrice,
}
