import React, { Suspense } from "react"
const SearchImpl = React.lazy(() => import("./SearchImpl"))

const Search = props => {
    if (typeof window === "undefined") return null

    return (
        <Suspense fallback={null}>
            <SearchImpl {...props} />
        </Suspense>
    )
}
export default Search
