import React from "react"
import { formatDate } from "../../util"

const DateRenderer = ({ info, subs, path, state, dispatch, ...args }) => {
    return (
	<div type-date="">
	    {formatDate(typeof info.value === "string" ? new Date(info.value) : info.value, "ro")}
	</div>
    )
    //return info.value
    //return <FaIcon icon={info.value} {...args} />
    //return <LazyImage {...args} />
}
export default DateRenderer
