import React from "react"
import { Entity, Query, useDeferredLoader } from "lib"
import Type from "../entity/type"
import Data from "../entity/data"
import { ifPromise } from "../entity/util"
import useLayout from "./useLayout"
import useAttributes from "./useAttributes"
import useAdmin from "./useAdmin"
//import usePrerender from "./usePrerender"

const getValueRec = (entity, parentName, parent, parentInfo, path, index = 0) => {
    const field = path[index]
    let value = Data.get(parent, field, { parentInfo })
    let typeInfo = Type.getKeyType(field, parent, parentInfo)
    //console.log(entity, parentName, parent, parentInfo, path, index, typeInfo, value)
    if(!typeInfo) {
	if(!parentName)
	    return { parent, parentInfo, field, value, typeInfo }
	
	const p = index>0
	      ?`${parentName}.${path.slice(0, index).join(".")}`
	      :parentName
	typeInfo = Type.getKeyType(`${p}.${field}`, entity)
	value = Data.get(entity, `${p}.${field}`)
    }
    if (index === path.length - 1) return { parent, parentInfo, field, value, typeInfo }

    const nextField = path[index + 1].split(".")[0]
    if (typeInfo.cache?.split(".").includes(nextField))
        return getValueRec(entity, parentName, value, typeInfo, path, index + 1)

    if (!typeInfo?.ref) return null
    const refType = Type.typeFromTypeDef(typeInfo.ref)
    if (!refType) return null
    const res = Query.execute({
        collection: refType.collection,
        query: { _id: value.ref },
    })
    return ifPromise(res, res => {
        const parent = res?.results?.[0]
        if (!parent) return null
        const parentInfo = Type.getType(parent)
        return getValueRec(entity, parentName, parent, parentInfo, path, index + 1)
    })
    /*
    if (res instanceof Promise) {
        return new Promise((resolve, reject) => {
            res.then(res => {
                const parent = res?.results?.[0]
                if (!parent) return resolve(null)
                const parentInfo = Type.getType(parent)
                return resolve(getValueRec(parent, parentInfo, path, index + 1))
            })
        })
    } else {
        const parent = res?.results?.[0]
        if (!parent) return resolve(null)
        const parentInfo = Type.getType(parent)
        return getValueRec(parent, parentInfo, path, index + 1)
    }*/
}
export const getValue = (entity, parentName, parent, parentInfo, field) => {
    if (!field) return parent
    const refHops = field.split(":")
    return getValueRec(entity, parentName, parent, parentInfo, refHops)
}

const uncapitalize = str => str[0].toLowerCase() + str.slice(1)
//const getNodePath = p => p.slice(1).reduce((acc, item) => [...acc, "children", item], [])
const getOldContext = info => {
    const { caller, _e, ...oldContext } = info?.context ?? {}
    return oldContext
}
const getOldContextInfo = info => {
    const { caller, ...oldContextInfo } = info?.context?._e ?? {}
    return oldContextInfo
}
const useInfo = (oldInfo, state, dispatch, args, domRef) => {
    const [load, renderID] = useDeferredLoader()
    const valueRef = React.useRef(0)
    const parentRef = React.useRef()
    //console.log("useInfo", oldInfo, state, args)
    const newInfo = React.useMemo(() => {
        //console.log(args, oldInfo)
        const { element, callerNode, children, ...rest } = oldInfo ?? {}
        let info = {
            ...rest,
            domRef,
            context: {
                ...getOldContext(oldInfo),
                attrs: args.attrs,
                ...(args.callerNode ? { caller: args.callerNode.node } : {}),
                _e: {
                    ...getOldContextInfo(oldInfo),
                    ...(args.callerNode ? { caller: "displayNode" } : {}),
                    attrs: "attrs",
                },
            },
            ...(args.callerNode ? { callerNode: args.callerNode } : {}),
            ...(args.children ? { children: args.children } : {}),
        }
        if (args.element) {
            //console.log("USEINFO element", args.element, args)
            if (args.attrs?._id) {
                const entity = load("entity", () => {
                    const type = Type.typeFromTypeDef(uncapitalize(args.element))
                    return Entity.loadById(args.attrs._id, type.collection)
                })
                if (!entity) return null
                //console.log("LOADID", args, entity)
                //console.log("USEINFO elementid - prepare info")
                //console.log("ENTITYLOADED", entity, "RenderID", renderID)
                //const entityType = entity.type
                const entityId = Entity.getId(entity)
                const entityInfo = Entity.getType(entity)
                if (!entityInfo) {
                    console.error("Type info not found for entity", entity)
                    return { ...oldInfo }
                }
                const entityType = Entity.realType(entityInfo)
                const context = {
                    ...info.context,
                    [entityType]: entity,
                    e: entity,
                    _e: {
                        ...(info.context._e ?? {}),
                        [entityType]: entityType,
                        e: entityType,
                    },
                }
                const value = entity
                const typeName = entityType
                const typeInfo = entityInfo
                const { user, language } = oldInfo
                info = {
                    domRef,
                    entity,
                    entityId,
                    entityType,
                    entityInfo,
                    context,
                    value,
                    typeName,
                    typeInfo,
                    user,
                    language,
                }
            }
            info = { ...info, element: args.element }
        }

        if (args.entity) {
            const entity = args.entity
            const entityId = Entity.getId(entity)
            const entityInfo = args.entityInfo ?? Entity.getType(entity)
            if (!entityInfo) {
                console.error("Type info not found for entity", entity)
                return { ...oldInfo }
            }
            const entityType = Entity.realType(entityInfo)
            const context = {
                ...info.context,
                [entityType]: entity,
                e: entity,
                _e: {
                    ...(info.context?._e ?? {}),
                    [entityType]: entityType,
                    e: entityType,
                },
            }
            //const entityType = Entity.realType(entityInfo) // ?? entityInfo.is //entity.type ?? entityInfo.name
            const value = entity
            const typeName = entityType
            const typeInfo = entityInfo
            const { user, language } = oldInfo??{}
            info = {
                domRef,
                entity,
                entityId,
                entityType,
                entityInfo,
                context,
                value,
                typeName,
                typeInfo,
                user,
                language,
            }
        }

        if (args.fieldName) {
            // FORMAT field.field:refEntity.field.field
            //console.log(args.fieldName)
            if (!info.value) {
                console.error("Parent not found for field", args.fieldName)
                return null
            }
            if (!info.typeInfo) {
                console.error("Parent type info not found for field", args.fieldName)
                return null
            }

            if (info.value !== parentRef.current) {
                valueRef.current += 1
                parentRef.current = info.value
            }
            const result = load(`value${valueRef.current}`, () =>
                getValue(info.entity, info.fieldName, info.value, info.typeInfo, args.fieldName)
            )
            //if (args.fieldName === "cart") console.log(info.value, result)
            if (!result) return null
            const { parent, parentInfo, field, value, typeInfo } = result
            if (!typeInfo) return null
            const toks = field.split(".")
            const fieldName = toks[toks.length - 1]
            const fieldPath = [...(info.fieldPath ?? []), ...toks]

            let entity = info.entity
            let entityId = info.entityId
            let entityType = info.entityType
            let entityInfo = info.entityInfo
            if (parent !== info.value) {
                entity = parent
                entityId = Entity.getId(entity)
                entityType = parent.type
                entityInfo = parentInfo
            }
            const parentType = Entity.realType(parentInfo)
            const typeName = Entity.realType(typeInfo) // ?? typeInfo.is //realType
            const context = {
                ...info.context,
                [entityType]: entity,
                [parentType]: parent,
                [typeName]: value,
                e: value,
                _e: {
                    ...(info.context._e ?? {}),
                    [entityType]: entityType,
                    [parentType]: parentType,
                    [typeName]: typeName,
                    e: typeName,
                },
            }
            //console.log(args.fieldName, typeName, value, parent)
            info = {
                ...info,
                entity,
                entityId,
                entityType,
                entityInfo,
                parent,
                parentInfo,
                fieldName,
                fieldPath,
                value,
                typeName,
                typeInfo,
                context,
            }
        }
        return { ...info, attrs: args.attrs }
    }, [load, renderID, oldInfo, args, domRef]) // eslint-disable-line react-hooks/exhaustive-deps
    //if (!newInfo) return [null, null]
    //if (args.fieldName === "cart") console.log("USEINFO", oldInfo, state, args, newInfo)
    const layout = useLayout(newInfo, args, state)
    //console.log("USEINFO layout", newInfo, layout)
    const [getCb, setAttr, attrs] = useAttributes(newInfo, args)
    //console.log("USEINFO attrs", attrs, newInfo)
    //const admin = useAdmin(newPath)

    const info = React.useMemo(() => {
        if (!newInfo) return newInfo
        return {
            ...newInfo,
            context: {
                ...(newInfo.context ?? {}),
                ...(layout?.context ?? {}),
                _e: {
                    ...(newInfo.context?._e ?? {}),
                    ...(layout?.context?._e ?? {}),
                },
            },
            layout,
            getCb,
            setAttr,
            wrapperAttrs: attrs,
            args,
        }
    }, [newInfo, layout, getCb, setAttr, attrs, args])
    //console.log("USEINFO info", info)
    useAdmin(info, oldInfo)
    //console.log("USEINFO", info)
    //console.groupEnd()
    return info
}
export default useInfo
