import React from "react"
import { Entity } from "lib"
import { Node } from "../Element"
//import { $keyOrder } from "../../entity/symbols"

const LayoutWidget = ({ domRef, info, state, dispatch, ...args }) => {
    //console.log(info.value.layout, Entity.getKeyType("layout", info.value, info.typeInfo), args) //info, state, args)
    return (
        <Node
            info={info}
            domRef={domRef}
            node={info.value?.layout}
            nodeInfo={Entity.getKeyType("layout", info.value, info.typeInfo)}
            nodePath={[0]}
            wrapper={true}
            state={state}
            dispatch={dispatch}
            attrs={args}
        />
    )
}
export default LayoutWidget
