import React from "react"
import { Entity, useUnmounted } from "lib"
import useInfo from "./useInfo"
import renderers from "./renderers"
import prerenderers from "./prerenderers"
import { $keyOrder } from "lib/entity/symbols"
import calc, { calcTag } from "../entity/calc"
import useElementState from "./useElementState"
import useTooltip from "./useTooltip"
//import useRerender from "./useRerender"
//let n = 0
//let n1 = 0
export const parseAttr = (attr, attrInfo, info, dispatch, init, isHTML) => {
    //n += 1
    //console.log(n, n1)
    //console.log("PARSE", attr, attrInfo, info)
    if (!attr) return init ?? {}
    if (!attrInfo || !attrInfo[$keyOrder]) {
        //console.log(attr, attrInfo, info, dispatch, init, isHTML)
        return init ?? {}
    }
    // n1 += 1
    return attrInfo[$keyOrder].reduce((acc, key) => {
        const keyConfig = attr?._e?.[key]
        const keyInfo = Entity.getKeyType(key, attr, attrInfo)
        const unionType = Entity.unionType(keyInfo)
        switch (unionType) {
            case "op": {
                /*if (attr?.[key]?.[0] === "a") {
                    return {
                        ...acc,
                        [key]: info.getCb(attr?.[key], info, dispatch),
                        _e: { ...acc._e, [key]: "fun" },
                    }
                }*/
                //console.log("PARSE", key, attr?.[key], calc(attr?.[key], info.context))
                const value = calc(
                    Entity.getValue(attr, key, { parentType: attrInfo }),
                    info.context
                )
                //console.log(attr, key, info.context, value)
                return {
                    ...acc,
                    ...(value instanceof Promise
                        ? { [key]: value }
                        : {
                              [key]: value.value,
                              _e: {
                                  ...(acc?._e ?? {}),
                                  [key]: value._e.value,
                              },
                          }),
                }
            }
            case "action":
            case "actionList": {
                //console.log(key, attr[key], unionType, keyInfo)
                //console.log(info)
                if (!info.getCb) return acc
                return {
                    ...acc,
                    [key]: info.getCb(attr?.[key], keyInfo, unionType, info, dispatch),
                    _e: { ...acc._e, [key]: "fun" },
                }
            }
            case "attrs":
            case "map": {
                const ret = {
                    ...acc,
                    [key]: attr?.[key]
                        ? parseAttr(attr?.[key], keyInfo, info, dispatch, {}, isHTML)
                        : attr?.[key],
                    _e: { ...acc._e, [key]: keyConfig ?? keyInfo },
                }
                //console.log("MAP", key, attr?.[key], ret, info)
                return ret
            }
            default: {
                //console.log(unionType)
                if (attr[key] === "_") {
                    return {
                        ...acc,
                        [key]: isHTML ? Entity.format(info.value, info.typeInfo) : info.value,
                        _e: { ...acc._e, [key]: "string" },
                    }
                }
                return { ...acc, [key]: attr[key], _e: { ...acc._e, [key]: keyConfig ?? keyInfo } }
            }
        }
    }, init ?? {})
}
//let n = 0
const HTMLTag = ({ tagName, info, domRef, wrapper, attrs, state, dispatch, children }) => {
    //n += 1
    //console.log(n)
    //HTMLTag
    const localRef = React.useRef()
    const ref = domRef ?? localRef
    const Tag = tagName //node?.data?.name
    const { _e, _index, content, renderChildren, tooltip, inputType, ...props } = attrs ?? {}
    const [renderTooltip, tprops] = useTooltip(info, attrs)
    const other = React.useMemo(() => (inputType ? { type: inputType } : {}), [inputType])

    React.useEffect(() => {
        //console.log("TAG", Tag, props)
        if (Tag !== "input") return
        //console.log(ref)
        if (!ref.current || !props.required) return
        const elem = ref.current
        const inputListener = () => {
            elem.setCustomValidity("")
            elem.checkValidity()
        }
        elem.addEventListener("input", inputListener)
        return () => {
            elem.removeEventListener("input", inputListener)
        }
    }, [Tag, ref, props.required])
    //console.log("HTMLTag", tagName, props, children)
    if (!Tag) return null
    //if (attrs?.className === "page-header-content")
    //    console.log("HTMLTag", tagName, attrs, info, wrapper)
    if (typeof content === "undefined" && !children && typeof renderChildren === "undefined") {
        //console.log("HTMLTag", tagName, props)
        return tprops ? (
            <>
                <Tag ref={ref} {...props} {...tprops} {...other} />
                {renderTooltip}
            </>
        ) : (
            <Tag ref={ref} {...props} {...other} />
        )
    }
    //console.log(tagName, content, children)
    if (typeof content === "object") {
        if (attrs._e?.content) {
            const entity = content
            const entityInfo = Entity.getType(entity, attrs._e.content)
            return (
                <Element
                    entity={entity}
                    entityInfo={entityInfo}
                    info={info}
                    state={state}
                    dispatch={dispatch}
                    attrs={props}
                />
            )
        }
        console.log("CONTENT IS OBJECT", tagName, attrs, info, wrapper)
        return null
    }
    //if (tagName === "div") console.log(props, wrapper)
    return (
        <Tag ref={ref} {...props} {...(tprops ?? {})} {...other}>
            {children?.(info.context, state, dispatch)}
            {content && <>{content}</>}
            {renderChildren && info.children}
            {renderTooltip}
        </Tag>
    )
    //{renderChildren && info.args.children && <>{info.args.children}</>}
}

const EmptyTag = ({ children, tagName, info, state, dispatch }) => {
    //console.log("EMPTYTAG", typeof children)
    return (
        <>
            {children?.(info.context, state, dispatch)}
            {tagName}
        </>
    )
}

const FieldTag = ({ domRef, wrapper, fields, attrs, ...props }) => {
    //console.log("FIELDTAG", fields, props)
    //if (!fields) console.log(fields, attrs, props)
    return (
        <>
            {fields?.map((fieldName, i) => {
                return (
                    <Element
                        key={i}
                        fieldName={fieldName}
                        attrs={{ ...(attrs ?? {}), _index: i }}
                        {...props}
                    />
                )
            })}
        </>
    )
}

const TagArray = ({
    info,
    domRef,
    wrapper,
    state,
    dispatch,
    tagArray,
    attrs,
    callerNode,
    ...props
}) => {
    //console.log("TAGARRAY", tagArray, typeof props.children)
    return (
        <>
            {tagArray.map((item, i) => {
                //console.log("TAGARRAY", item)
                return (
                    <Element
                        key={i}
                        info={info}
                        element={item.element}
                        attrs={{ ...(attrs ?? {}), _index: i, data: item.data }}
                        state={state}
                        dispatch={dispatch}
                        callerNode={callerNode}
                        {...props}
                    />
                )
            })}
        </>
    )
}
const ElementTag = ({ tagName, domRef, wrapper, ...props }) => {
    //console.log("ELEMENTTAG", tagName, props)
    return tagName ? <Element element={tagName} {...props} /> : <Element {...props} />
}
const ElementsTag = ({ entities, domRef, wrapper, ...props }) => {
    //console.log(entities, props)
    return entities.map((e, i) => <Element key={i} entity={e.entity} {...props} />)
    //tagName ? <Element element={tagName} {...props} /> : <Element {...props} />
}
const UnknownRenderer = () => {
return ''
}
const Renderer = ({ renderer, wrapper, _index, ...props }) => {
    //console.log("RENDERER", renderer, typeof props.children)
    //console.log("RENDERER", renderer, props)
    const Comp = renderers[renderer]
    if (!Comp) {
        console.log("Renderer not found1", renderer)
        return null
    }

    return <Comp {...props} />
}
const RenderTag = ({ tagName, attrs, info, wrapper, callerNode, ...props }) => {
    //console.log("RENDERTAG", tagName, props)
    const { _index, ...rest } = attrs ?? {}
    //const wrapperAttrs
    //console.log("RENDERTAG", tagName, attrs, props, _index)
    //const style = { ...(attrs?.style ?? props?.style ?? {}), "--index": _index }
    const Comp = renderers[tagName]
    if (!Comp) {
        console.log("Renderer not found2", tagName)
        return props.children ? (
            <>{props.children(info.context, props.state, props.dispatch)}</>
        ) : null
    }
    //            {children(info.context, props.state, props.dispatch)}

    /*return children ? (
        <Comp info={info} {...props} {...rest}>
            {children(info.context, props.state, props.dispatch)}
        </Comp>
    ) : (*/
    return <Comp info={info} {...props} {...rest} />
    //)
}

const getTag = nameValue => {
    if (/^[A-Z]/.test(nameValue)) {
        if (renderers[nameValue]) return [RenderTag, nameValue, false]
        return [ElementTag, nameValue, true]
    }
    const name = nameValue //getDataValue(nameValue, info)
    if (typeof name !== "string") return [null, null, null]
    if (name === "") return [EmptyTag, name, false]
    return [HTMLTag, name, false]
}

const NodeChildren = ({
    //callerHasChildren,
    node,
    nodeInfo,
    nodePath,
    info,
    state,
    dispatch,
    context,
    children,
}) => {
    //console.log(node, info, nodeInfo)
    const newInfo = info.context === context ? info : { ...info, context }
    if (!node) {
        //console.log("NOT NODE", children)
        // || !node.children || node.children.length === 0) {
        return children ? <>{children(info.context, state, dispatch)}</> : null
    }
    //const props = children ? { children } : {}
    return node.children.map((n, i) => (
        <Node
            key={i}
            info={newInfo}
            node={n}
            nodeInfo={Entity.getKeyType(`children.${i}`, node, nodeInfo)}
            nodePath={[...nodePath, i]}
            state={state}
            dispatch={dispatch}
            attrs={{ _index: i }}
            //callerHasChildren={callerHasChildren}
            //{...props}
        ></Node>
    ))
}
//let count = 0
export const Node = ({
    info,
    domRef,
    node,
    nodeInfo,
    nodePath,
    attrs,
    wrapper,
    state,
    dispatch,
    //callerHasChildren,
}) => {
    //console.log("NODE", node?.data)
    const [renderID, triggerRender] = React.useState(0)
    const unmounted = useUnmounted()
    const waitFor = React.useCallback(
        async p => {
            //let c = count
            //count++
            //console.log("NODE WAIT", c, p)
            if (Array.isArray(p)) {
                return Promise.all(p).then(() => {
                    if (unmounted.current) return
                    //console.log("NODE TRIGGER", c, p)
                    triggerRender(v => (v + 1) % 100)
                })
            }
            await p
            if (unmounted.current) return
            triggerRender(v => (v + 1) % 100)
        },
        [unmounted]
    )
    const tagInfo = React.useMemo(() => {
        if (!node) return null
        const dataInfo = Entity.getKeyType("data", node, nodeInfo)
        if (!node?.data || !dataInfo) {
            console.log("Node null", node, nodeInfo, info)
            return { Tag: EmptyTag, isElement: false }
        }

        const nameInfo = Entity.getKeyType("data.name", node, nodeInfo)
        if (!node.data.name || !nameInfo) {
            console.log("NO NAME INFO", node.data, nameInfo)
            return null
        }
        const nameType = Entity.unionType(nameInfo) ?? nameInfo.is
        let ret

        switch (nameType) {
        //case "href":
          //  console.log("------------------------------------------------------------------------NODE NAMETYPE", nameType, node.data.name, nameInfo)
            //return null
            case "op": {
                //Array.isArray(node.data.name)) {
                //if (node.data.name?.data) console.trace("HERE2")
                const result = calcTag(
                    Entity.getValue(node, "data.name", { parentType: nodeInfo }),
                    info.context
                )
                //console.log("CALCTAG", node.data.name, result, info.context)
                if (result instanceof Promise) {
                    waitFor(result)
                    return null
                }
                //console.log("RESULT", node.data?.name, result, typeof result)
                if (result) {
                    switch (typeof result) {
                        case "number": {
                            break
                        }
                        case "string": {
                            if (result === "") ret = { Tag: EmptyTag, isElement: false }
                            else
                                ret = {
                                    Tag: HTMLTag,
                                    //name,
                                    props: { tagName: result },
                                    isElement: false,
                                }
                            break
                        }
                        default: {
                            if (Array.isArray(result)) {
                                //console.log(result)
                                if (result.length > 0) {
                                    if (result[0]?.element) {
                                        //console.log("TAGARRAY", result)
                                        ret = {
                                            Tag: TagArray,
                                            props: {
                                                tagArray: result,
                                            },
                                            isElement: true,
                                        }
                                    } else {
                                        if (result[0]?.entity) {
                                            ret = {
                                                Tag: ElementsTag,
                                                props: {
                                                    entities: result,
                                                },
                                                isElement: true,
                                            }
                                        }
                                    }
                                }
                            } else {
                                if (result.entity) {
                                    //console.log("ENTITY:", result, info)
                                    //return null
                                    if (result.entity === info.value) {
                                        if (result.fields) {
                                            ret = {
                                                Tag: FieldTag,
                                                props: {
                                                    fields: result.fields,
                                                },
                                                isElement: true,
                                            }
                                        } else {
                                            if (result.entityInfo.renderer) {
                                                ret = {
                                                    Tag: RenderTag,
                                                    props: {
                                                        tagName: result.entityInfo.renderer,
                                                        //attrs: newAttrs,
                                                    },
                                                    isElement: false,
                                                }
                                            }
                                        }
                                    } else {
                                        ret = {
                                            Tag: ElementTag,
                                            props: {
                                                entity: result.entity,
                                                entityInfo: result.entityInfo,
                                                //value: result.entity,
                                                //type: result.entityInfo,
                                                ...(result.fields
                                                    ? { fieldName: result.fields[0] }
                                                    : {}),
                                            },
                                            isElement: true,
                                        }
                                    }
                                } else {
                                    if (result.data) {
                                        //console.log(result.data)
                                        const { _e, ...newAttrs } = parseAttr(
                                            result?.data?.attr,
                                            Entity.getType(result?.data?.attr, {
                                                is: "map",
                                                values: "exprValExt",
                                            }),
                                            info,

                                            dispatch,
                                            {
                                                ...(wrapper ? info.wrapperAttrs ?? {} : {}),
                                                ...(attrs ?? {}),
                                            },
                                            renderers[result.data.name]
                                        )
                                        //console.log(newAttrs)
                                        if (renderers[result.data.name]) {
                                            ret = {
                                                Tag: RenderTag,
                                                props: {
                                                    tagName: result.data.name,
                                                    attrs: newAttrs,
                                                },
                                                isElement: false,
                                            }
                                        } else {
                                            ret = {
                                                Tag: ElementTag,
                                                props: {
                                                    tagName: result.data.name,
                                                    attrs: newAttrs,
                                                },
                                                isElement: true,
                                            }
                                        }
                                    } else {
                                        if (result.value) {
                                            ret = {
                                                Tag: EmptyTag,
                                                props: { tagName: result.value },
                                                isElement: false,
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                if (!ret) {
                    //console.log("RESULT", node.data.name, result, info)
                }
                break
            }
            case "string": {
                const [Tag, tagName, isElement] = getTag(node.data.name, info)
                if (!Tag) return null
                ret = { Tag, props: { tagName }, isElement }
                /*if (Tag === ElementTag) {
                    console.log("ELEM", node, info)
                    //ret = null
                }*/
                break
            }
            default: {
                //console.log("DEFAULT", node.data.name, nameInfo)

                ret = {
                    Tag: ElementTag,
                    props: {
                        entity: node.data.name,
                        entityInfo: nameInfo,
                    },
                    isElement: true,
                }
            }
        }
        if (!ret) return null
        const { Tag } = ret

        //const getAttr = (node, nodeInfo, info, wrapper, isHTML) =>
        //const { _e, ...newAttrs } = parseAttr(
        const newAttrs = parseAttr(
            node?.data?.attr,
            Entity.getType(node?.data?.attr, "attrs"), //Entity.getKeyType("data.attr", node, nodeInfo),
            info,

            dispatch,
            { ...(wrapper ? info.wrapperAttrs ?? {} : {}), ...(attrs ?? {}) },
            Tag === HTMLTag
        )
        //console.log(newAttrs, node, info)
        const promises = Object.values(newAttrs).filter(v => v instanceof Promise)
        if (promises.length > 0) {
            waitFor(promises)
            return null
        }
        return { ...ret, newAttrs }
    }, [waitFor, info, node, nodeInfo, dispatch, wrapper, attrs, renderID]) // eslint-disable-line react-hooks/exhaustive-deps
    //console.log("NODE TAGINFO", tagInfo)
    if (!node) {
        /*if (callerHasChildren) {
            console.log("not node")
            return null
            }*/
        if (!info.callerNode) return null
        //console.log("NOT NODE RETNULL", info.callerNode)
        return (
            <NodeChildren
                context={info.context}
                state={state}
                dispatch={dispatch}
                {...info.callerNode}
            />
        )
    }
    if (!tagInfo) {
        //console.log("NODE: no tag info", node, info)
        return null
    }
    const { Tag, props, isElement } = tagInfo

    if (Object.keys(tagInfo.newAttrs ?? {}).includes("if") && !tagInfo.newAttrs.if) {
        //console.log(tagInfo?.newAttrs?.if, node, info)
        return null
    }
    const newAttrs =
        tagInfo.newAttrs && Object.keys(tagInfo.newAttrs).includes("if")
            ? Object.keys(tagInfo.newAttrs)
                  .filter(key => key !== "if")
                  .reduce((acc, key) => ({ ...acc, [key]: tagInfo.newAttrs[key] }), {})
            : tagInfo.newAttrs
    //if (newAttrs?.value) console.log(node, info, newAttrs)
    //const {if, newAttrs} = tagInfo.newAttrs
    //if (wrapper) console.log("WRAPPER", Tag.name, node.data, node.children, newAttrs, info)
    const callerNode = node.children?.length > 0 ? { node, nodeInfo, nodePath, info } : null
    //const hasChildren = callerHasChildren || node.children?.length > 0
    //if (newAttrs?.renderChildren) console.log(node, info.callerNode, newAttrs?.renderChildren)
    //if (info.children) console.log(info.children, node, info, newAttrs, isElement, callerNode)
    //if (newAttrs.content) console.log(newAttrs, props)
    if (isElement) {
        if (callerNode) {
            props.callerNode = callerNode
        } else {
            if (info.callerNode) {
                if (newAttrs?.renderChildren) {
                    props.callerNode = info.callerNode
                }
            }
        }
    } else {
        if (callerNode) {
            //console.log("CALLER1", callerNode, node, info, nodeInfo)
            return (
                <Tag
                    info={info}
                    domRef={domRef}
                    wrapper={wrapper}
                    state={state}
                    dispatch={dispatch}
                    attrs={newAttrs}
                    {...props}
                >
                    {(context, state, dispatch) => (
                        <NodeChildren
                            state={state}
                            dispatch={dispatch}
                            context={context}
                            {...callerNode}
                        />
                    )}
                </Tag>
            )
        } else {
            //if (node?.data?.name === "main") console.log(node, info)
            if (info.callerNode && typeof newAttrs?.renderChildren !== "undefined") {
                //console.log("CALLER2", info.callerNode, node, info, nodeInfo, props, newAttrs)
                return (
                    <Tag
                        info={info}
                        domRef={domRef}
                        wrapper={wrapper}
                        state={state}
                        dispatch={dispatch}
                        attrs={newAttrs}
                        {...props}
                    >
                        {(context, state, dispatch) => (
                            <NodeChildren
                                state={state}
                                dispatch={dispatch}
                                context={context}
                                {...info.callerNode}
                            />
                        )}
                    </Tag>
                )
            }
        }
    }
    /*if (info.children && newAttrs.renderChildren)
        return (
            <Tag
                info={info}
                domRef={domRef}
                wrapper={wrapper}
                state={state}
                dispatch={dispatch}
                attrs={newAttrs}
                {...props}
            >
                {info.children}
            </Tag>
        )*/
    return (
        <Tag
            info={info}
            domRef={domRef}
            wrapper={wrapper}
            state={state}
            dispatch={dispatch}
            attrs={newAttrs}
            {...props}
        />
    )
}

const WithState = ({ domRef, node, nodeInfo, nodePath, wrapper, Prerenderer, ...props }) => {
    const [info, state, dispatch] = useElementState(props)
    //useRerender(info)
    //console.log(info, state)
    if (!state) return null
    if (Prerenderer)
        return (
            <Prerenderer info={info}>
                {info => (
                    <Node
                        info={info}
                        domRef={domRef}
                        node={node}
                        nodeInfo={nodeInfo}
                        nodePath={nodePath}
                        wrapper={wrapper}
                        state={state}
                        dispatch={dispatch}
                    />
                )}
            </Prerenderer>
        )
    return (
        <Node
            info={info}
            domRef={domRef}
            node={node}
            nodeInfo={nodeInfo}
            nodePath={nodePath}
            wrapper={wrapper}
            state={state}
            dispatch={dispatch}
        />
    )
}
const NoLayoutRenderer = ({ domRef, info, state, dispatch, args }) => {
    if (args.element) {
        const [Tag, tagName] = getTag(args.element, info)
        //console.log("ELEMENT NOLAYOUT", args.element, tagName, Tag === ElementTag)
        //return null
        if (Tag === ElementTag) {
            console.log(`Element "${args.element}" has no render info.`, info, args)
            /*if (args.callerNode)
                return (
                    <NodeChildren
                        context={info.context}
                        state={state}
                        dispatch={dispatch}
                        {...args.callerNode}
                    />
                )
            console.log(`Element "${args.element}" has no render info.`, info, args)
            //console.log("NOT NODE RETNULL", info.callerNode)*/
            return null
        }
        if (Tag) {
            return args.children ? (
                <Tag
                    tagName={tagName}
                    info={info}
                    domRef={domRef}
                    wrapper={true}
                    state={state}
                    dispatch={dispatch}
                    {...info.attrs}
                    {...info.wrapperAttrs}
                >
                    {args.children}
                </Tag>
            ) : (
                <Tag
                    tagName={tagName}
                    info={info}
                    domRef={domRef}
                    wrapper={true}
                    state={state}
                    dispatch={dispatch}
                    {...info.attrs}
                    {...info.wrapperAttrs}
                />
            )
        }
        return null
    }

    if (info?.typeInfo?.renderer) {
        //console.log("ELEMENT RENDERER", newInfo?.typeInfo?.renderer, newInfo)
        return args.children ? (
            <Renderer
                renderer={info?.typeInfo?.renderer}
                info={info}
                domRef={domRef}
                state={state}
                dispatch={dispatch}
                wrapper={true}
                {...info.attrs}
                {...info.wrapperAttrs}
            >
                {args.children}
            </Renderer>
        ) : (
            <Renderer
                renderer={info?.typeInfo?.renderer}
                info={info}
                domRef={domRef}
                state={state}
                dispatch={dispatch}
                wrapper={true}
                {...info.attrs}
                {...info.wrapperAttrs}
            />
        )
    }
    if (info.typeInfo?.classes?.includes("list") && info.value?.length > 0) {
        //console.log("ELEMENTLIST", newInfo)
        return (
            <>
                {info.value.map((item, i) => (
                    <Element
                        key={i}
                        info={info}
                        fieldName={`${i}`}
                        attrs={{ ...(info.attrs ?? {}), _index: i }}
                        state={state}
                        dispatch={dispatch}
                    />
                ))}
            </>
        )
    }
    if (args.callerNode)
        return (
            <NodeChildren
                context={info.context}
                state={state}
                dispatch={dispatch}
                {...args.callerNode}
            />
        )
    console.log("No render info for object:", info, args)
    return null
}
// args: element, entity, fieldName, attrs
const useArgs = newArgs => {
    const argsRef = React.useRef({})
    if (
        Object.keys(newArgs).reduce(
            (acc, key) => acc || argsRef.current[key] !== newArgs[key],
            false
        )
    )
        argsRef.current = newArgs
    return argsRef.current
}

const ElementWidget = props => {
    //console.log("element", props)
    const { info, state, dispatch, ...newArgs } = props
    const domRef = React.useRef()
    const args = useArgs(newArgs)
    //useRerender(props)
    const newInfo = useInfo(info, state, dispatch, args, domRef)
    //console.log(newInfo)
    if (!newInfo) return null
    /*console.log("element newInfo", newInfo)
    if(props.entityInfo && !props.entity) {
        console.log("=============================================================")
        return null
    }*/
    //if(!newInfo.element) return null
    //console.log("PATH > 10; recursive?", newInfo?.path, newInfo)
    /*if (!newInfo.path) {
        console.log("NOPATH?", props, newInfo)
        return null
    }*/
    if (newInfo?.path?.length > 10) {
        console.log("PATH > 10; recursive?", props, newInfo)
        return null
    }
    //console.log("ELEMENT", newInfo.path?.length ?? 0)
    const Prerenderer = newInfo.typeInfo?.prerender
        ? prerenderers[newInfo.typeInfo.prerender]
        : null
    //console.log(Prerenderer)
    const layout = newInfo?.layout
    if (!layout) {
        //console.log(args.element ?? newInfo?.typeInfo?.renderer, newInfo)
        if (typeof layout === "undefined") return null
        if (Prerenderer) {
            return (
                <Prerenderer info={newInfo}>
                    {info => (
                        <NoLayoutRenderer
                            info={info}
                            domRef={domRef}
                            state={state}
                            dispatch={dispatch}
                            args={args}
                        />
                    )}
                </Prerenderer>
            )
        }
        return (
            <NoLayoutRenderer
                info={newInfo}
                domRef={domRef}
                state={state}
                dispatch={dispatch}
                args={args}
            />
        )
    }
    //console.log(layout?.withState, layout?.root)
    //const Comp = layout.state || layout.subscriptions || layout.handlers ? WithState : Node
    if (layout.state || layout.subscriptions || layout.handlers)
        return (
            <WithState
                info={newInfo}
                domRef={domRef}
                node={layout?.root}
                nodeInfo={layout?.rootInfo}
                nodePath={[0]}
                wrapper={true}
                state={state}
                dispatch={dispatch}
                Prerenderer={Prerenderer}
            />
        )
    if (Prerenderer)
        return (
            <Prerenderer info={newInfo}>
                {info => (
                    <Node
                        info={info}
                        domRef={domRef}
                        node={layout?.root}
                        nodeInfo={layout?.rootInfo}
                        nodePath={[0]}
                        wrapper={true}
                        state={state}
                        dispatch={dispatch}
                    />
                )}
            </Prerenderer>
        )
    return (
        <Node
            info={newInfo}
            domRef={domRef}
            node={layout?.root}
            nodeInfo={layout?.rootInfo}
            nodePath={[0]}
            wrapper={true}
            state={state}
            dispatch={dispatch}
        />
    )
}
const Element = React.memo(ElementWidget)
export default Element
