import React from "react"
import { History, useHistory, useSubscription } from "lib"
import Element from "../display/Element"
//import { retrieveStore } from "../display/renderers/NodeTransition"

const Site = () => {
    //const [loadingStore, setLoadingStore] = React.useState()
    const [user, setUser] = React.useState()
    const [isShell, setIsShell] = React.useState(History.getLocation().pathname === "/shell")
    useHistory(location => {
        //console.log("Site isShell", location.pathname === "/shell")
        setIsShell(location.pathname === "/shell")
    })
    //console.log("RENDER SITE")
    const handleSub = React.useCallback((topic, arg) => {
        //if (url === location.pathname) return
        //console.log("SITE openurl", url)
        switch (topic) {
            case "openURL":
                History.push(arg)
                return
            default:
                setUser(arg)
        }
    }, [])
    useSubscription(["openURL", "user"], handleSub)
    const info = React.useMemo(() => {
        //console.log("SITE NEW INFO")
        return {
            user,
            context: { user },
        }
    }, [user])
    /*React.useMemo(() => {
        if (typeof window === "undefined" || window.isHydrating) return
        const pathname = window.location.pathname
        if (pathname === "/shell") return
        setLoadingStore(true)
        retrieveStore(pathname).then(() => {
            //console.log(data)
            setLoadingStore(false)
        })
    }, [])*/
    React.useEffect(() => {
        if (isShell) return
        setTimeout(() => {
            requestIdleCallback(() => {
                navigator?.serviceWorker?.controller?.postMessage({
                    type: "SITE-LOADED",
                    payload: window.location.pathname,
                })
            })
        }, 5000)
    }, [isShell])
    if (isShell) return null
    return <Element info={info} element="Site" />
}
export default Site
