import NodeTransition from "./NodeTransition"
import HTML from "./Html"
import Dialog from "./Dialog"
import Image from "./Image"
import ImageList from "./ImageList"
import Icon from "./Icon"
import Link from "./Link"
import Carousel from "./Carousel"
import Track from "./Track"
import ItemTransition from "./ItemTransition"
import ElemWidget from "./ElemWidget"
import LayoutWidget from "./LayoutWidget"
import Slider from "./Slider"
import Portal from "./Portal"
import Navigator from "./Navigator"
import Date from "./Date"
import Video from "./Video"
import Pager from "./Pager"
import customRenderers from "conf/renderers"

const renderers = {
    NodeTransition,
    Image,
    ImageList,
    Icon,
    Date,
    Carousel,
    Track,
    Navigator,
    HTML,
    Link,
    Dialog,
    ItemTransition,
    ElemWidget,
    LayoutWidget,
    Slider,
    Portal,
    Video,
    Pager,
    ...customRenderers,
}

export default renderers
