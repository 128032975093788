// @flow
import NProgress from "nprogress"
import C from "../conf"

type Param = [string, string]
type CallBack = (path: string, search: Array<Param>) => void

let onChangeListeners = []

let location = {}
let prevLocation = {}

const prepareSearch = (search: ?string): Array<any> => {
    if (!search) return []
    return search
        .slice(1)
        .split("&")
        .map(item => item.split("="))
        .reduce(
            (params, param) => ({
                ...params,
                [param[0]]: param[1],
            }),
            {}
        )
}
const getLanguage = pathname => {
    if (!C.LANGUAGES) return null

    const toks = pathname.replace(/\.html$/, "").split("/")
    if (toks.length < 2) return C.LANGUAGES[0]
    const lang = toks[1]
    if (C.LANGUAGES.indexOf(lang) < 0) {
        return C.LANGUAGES[0]
    }
    return lang
}
const push = (pathname: string, options = {}) => {
    //console.log("HISTORY PUSH", pathname)
    const toks = pathname.split("?")
    const p =
        toks[0] === "/" ? "/index.html" : /\.html$/.test(toks[0]) ? toks[0] : `${toks[0]}.html`
    if (window !== window.top) {
        console.log("HISTORY window!==window.top", window, window.top)
        window.top.pushLocation(`${p}?mode=design`, options)
        return
    }
    if (toks[1]) realPushLocation(`${p}?${toks[1]}`, options)
    else realPushLocation(p, options)
}
const pushChildLocation = (pathname: string) => {
    realPushLocation(pathname)
}
const realPushLocation = (pathname: string, options) => {
    //console.log(pathname)
    prevLocation = { ...location }
    const prevPathname = location.pathname
    location = {}
    const language = getLanguage(pathname)
    //requestAnimationFrame(() => {
    //console.log("HISTORY push WINDOW", pathname)
    window.history.pushState({ pathname }, "", pathname)

    location.pathname = document.location.pathname
    location.sanitized =
        location.pathname === "/index.html" ? "/" : location.pathname.replace(/\.html$/, "")
    location.hash = document.location.hash
    location.language = language
    location.search = prepareSearch(document.location.search)
    if (options?.nocb) return
    if (
        prevPathname !== document.location.pathname &&
        !(location.search["mode"] && location.search["mode"] === "design")
    ) {
        //console.log(location, document.location.pathname)
        requestAnimationFrame(() => {
            NProgress.configure({ parent: "body" })
            NProgress.start()
        })
    }
    onChangeListeners.forEach(callback => {
        //console.log("HISTORY CB", location.pathname)
        callback(location.pathname, location.search, location.language, location.sanitized)
    })
    //})
}

const addLocationListener = (cb: CallBack) => {
    //console.trace("add locationlistener")
    onChangeListeners.push(cb)
}
const removeLocationListener = (cb: CallBack) => {
    onChangeListeners = onChangeListeners.filter(fn => fn !== cb)
}

const isSSR = typeof window === "undefined"
if (!isSSR) {
    location.pathname = document.location.pathname
    location.sanitized =
        location.pathname === "/index.html" ? "/" : location.pathname.replace(/\.html$/, "")
    location.hash = document.location.hash
    location.search = prepareSearch(document.location.search)
    location.language = getLanguage(location.pathname)
    window.pushLocation = realPushLocation
    window.pushChildLocation = pushChildLocation
    if (!location.search["mode"] || location.search["mode"] !== "design") NProgress.start()
    window.onpopstate = e => {
        if (!e.state) return
        const loc = { ...location }
        //console.log(loc, e)
        if (prevLocation && e.state && e.state.pathname === prevLocation.pathname)
            location = { ...prevLocation }
        else location = {}
        prevLocation = loc
        //console.log(location.pathname, document.location)
        if (location.pathname !== document.location.pathname) {
            NProgress.configure({ parent: "body" })
            NProgress.start()
        }

        location.pathname = document.location.pathname
        location.sanitized =
            location.pathname === "/index.html" ? "/" : location.pathname.replace(/\.html$/, "")
        location.hash = document.location.hash
        location.search = prepareSearch(document.location.search)
        location.language = getLanguage(location.pathname)
        onChangeListeners.forEach(callback =>
            callback(location.pathname, location.search, location.language, location.sanitized)
        )
    }
}
const ssrSetLocation = (pathname, search) => {
    location.pathname = pathname
    location.sanitized =
        location.pathname === "/index.html" ? "/" : location.pathname.replace(/\.html$/, "")
    location.hash = null
    location.search = search
    location.language = getLanguage(location.pathname)
}

const getLocation = () => ({ ...location })
const getPrevPage = () => prevLocation

export default {
    ssrSetLocation,
    getLocation,
    push,
    addLocationListener,
    removeLocationListener,
    getPrevPage,
    prepareSearch,
}
