import Data from "./data"
import Type from "./type"
import Query from "../query/query"
import { $keyOrder, $lineage } from "./symbols"
import { ifPromise, ifPromiseArray } from "./util"
import ops from "./ops"

const getValueRec = (parent, path, index = 0) => {
    const toks = path[index].split(".")
    const all = toks[toks.length - 1] === "_all_"
    const rest = toks[toks.length - 1] === "_rest_"
    const field = all || rest ? toks.slice(0, toks.length - 1).join(".") : path[index]

    const key = `value.${field}`
    const value = Data.getValue(parent, key)
    //if (/itemsMap/.test(field)) console.log(parent, path, index, key, value)
    const valueType = Type.getKeyType("value", value)
    if (all) {
        const values = valueType?.[$keyOrder].reduce(
            (acc, key) => Data.appendValue(acc, "value", Data.getValue(value, `value.${key}`)),
            Data.createValue([], "list")
        )

        return values
    }
    if (rest) {
        const values = (valueType?.[$keyOrder] ?? [])
            .filter(key => valueType.keys[key]?.keyType === "dynamic")
            .reduce(
                (acc, key) => Data.appendValue(acc, "value", Data.getValue(value, `value.${key}`)),
                Data.createValue([], "list")
            )

        return values
    }

    if (index === path.length - 1) return value //createValue(value, type)

    if (!valueType?.ref) return Data.createValue(null)
    const nextField = path[index + 1].split(".")[0]
    if (nextField === "ref" || valueType.cache?.split(",").includes(nextField))
        return getValueRec(value, path, index + 1)

    const refType = Type.typeFromTypeDef(valueType.ref)
    if (!refType) return Data.createValue(null)
    const res = Query.execute({
        collection: refType.collection,
        query: { _id: value.value?.ref },
    })
    return ifPromise(res, res => {
        const parent = res?.results?.[0]
        if (!parent) return Data.createValue(null)
        const p = Data.createValue(parent)
        const ret = getValueRec(p, path, index + 1)
        return ret
    })
}
const getValue = (parent, field) => {
    //if (!field) return Data.createValue(parent, parentType)
    //console.log("GETVALUE", parent, parentType, field)
    const refHops = `${field}`.split(":")
    const ret = getValueRec(parent, refHops)
    //console.log("GETVALUE RET", field, refHops, parent, parentType, ret)
    return ret
}

const switchOp = (op, args, context) => {
    //console.log("switchOp", op, args, context)
    //const contextType = Type.getType(context, "map")
    const contextValue = Data.createValue(context, "map")
    switch (op) {
        case "f": {
            // FIELD
            if (!args[0]?.value) return Data.createValue()
            const ret = getValue(contextValue, args[0].value)
            return ret
        }
        case "len": {
            //console.log("LEN", args)
            return Data.createValue(args[0]?.value?.length ?? 0, "int")
        }
        case "get": {
            //console.log("GET", args, context)
            if (!args[0]?.value) return Data.createValue()
            const field = args[1]?.value ? `${args[0].value}.${args[1].value}` : args[0]?.value
            if (!field) return Data.createValue()
            const ret = getValue(contextValue, field)
            //console.log(args, field, contextValue, ret)
            return ret
        }
        case "+": {
            if (args.length === 0) return Data.createValue()
            const ret = ops.plus(args)
            //console.log("PLUS", args, ret)
            return ret
        }
        case "-": {
            if (args.length === 0) return Data.createValue()
            return ops.minus(args)
        }
        case "*": {
            if (args.length === 0) return Data.createValue()
            const ret = ops.times(args)
            //console.log("TIMES", args, ret)
            return ret
        }
        case "/": {
            if (args.length === 0) return Data.createValue()
            return ops.div(args)
        }
        case "**": {
            if (args.length === 0) return Data.createValue()
            return ops.pow(args)
        }
        case ">": {
            //console.log(">", args)
            const left = /^\d+$/.test(args[0].value) ? parseInt(args[0].value) : args[0].value
            const right = /^\d+$/.test(args[1].value) ? parseInt(args[1].value) : args[1].value
            return Data.createValue(left > right, "bool")
        }
        case ">=": {
            //console.log(">", args)
            const left = /^\d+$/.test(args[0].value) ? parseInt(args[0].value) : args[0].value
            const right = /^\d+$/.test(args[1].value) ? parseInt(args[1].value) : args[1].value
            return Data.createValue(left >= right, "bool")
        }
        case "<": {
            //console.log(">", args)
            const left = /^\d+$/.test(args[0].value) ? parseInt(args[0].value) : args[0].value
            const right = /^\d+$/.test(args[1].value) ? parseInt(args[1].value) : args[1].value
            return Data.createValue(left < right, "bool")
        }
        case "<=": {
            //console.log(">", args)
            const left = /^\d+$/.test(args[0].value) ? parseInt(args[0].value) : args[0].value
            const right = /^\d+$/.test(args[1].value) ? parseInt(args[1].value) : args[1].value
            return Data.createValue(left <= right, "bool")
        }
        case "=": {
            const left = /^\d+$/.test(args[0].value) ? parseInt(args[0].value) : args[0].value
            const right = /^\d+$/.test(args[1].value) ? parseInt(args[1].value) : args[1].value
            //console.log("=", args, left, right)
            return Data.createValue(left === right, "bool")
        }
        case "!=": {
            //console.log(">", args)
            const left = /^\d+$/.test(args[0].value) ? parseInt(args[0].value) : args[0].value
            const right = /^\d+$/.test(args[1].value) ? parseInt(args[1].value) : args[1].value
            return Data.createValue(left !== right, "bool")
        }
        case "&&": {
            return Data.createValue(
                args.reduce((acc, item) => acc && item.value, true),
                "bool"
            )
        }
        case "||": {
            return Data.createValue(
                args.reduce((acc, item) => acc || item.value, false),
                "bool"
            )
        }
        case "!": {
            return Data.createValue(!args[0].value, "bool")
        }
        case "is": {
            const t = Type.getKeyType("value", args[0])
            const ret = t?.[$lineage]?.includes(args[1]?.value)
            //console.log("IS", args, t, ret)
            return Data.createValue(ret, "bool")
        }
        case "fmt": {
            const value = args[0].value
            const locale = args[1].value
            const fdigits = args[2].value
            if (typeof value === "undefined") return Data.createValue("", "string")
            const ret = Data.createValue(
                value.toLocaleString(locale, {
                    minimumFractionDigits: fdigits,
                    maximumFractionDigits: fdigits,
                }),
                "string"
            )
            //console.log("FMT", args, ret)
            return ret
        }
        case "att": {
            const ret = Data.createValue(
                typeof args[0]?.value === "object" ? Object.keys(args[0]?.value ?? {}) : [],
                "stringList"
            )
            return ret
        }
        case "val": {
            return Data.createValue(
                typeof args[0].value === "object" ? Object.values(args[0].value ?? {}) : [],
                "list"
            )
        }
        case "ent": {
            return Data.createValue(
                (typeof args[0].value === "object" ? Object.entries(args[0].value ?? {}) : []).map(
                    item => ({ a: item[0], v: item[1] })
                ),
                { is: "list", items: "attr" }
            )
        }
        case "zip": {
            //console.log("ZIP", args)
            if (!Array.isArray(args[0].value) || !Array.isArray(args[1].value))
                return Data.createValue(null)
            const ret = args[0].value.reduce(
                (acc, key, i) =>
                    Data.setValue(acc, `value.${key}`, Data.getValue(args[1], `value.${i}`)),
                Data.createValue({}, "map")
            )
            //console.log("ZIP", args, ret)
            return ret
        }
        default:
            return Data.createValue([op, ...(args.map(a => a.value) ?? [])], "list")
    }
}

const fallbackTry = (alternatives, context, index) => {
    //console.log("FALLBACK", alternatives, index, context)
    if (index >= alternatives.value.length) return Data.createValue(null)
    return ifPromise(calc(get(index, alternatives), context), result => {
        //console.log(result)
        if (!result || typeof result.value === "undefined" || result.value === null) {
            if (index + 1 < alternatives.value.length)
                return fallbackTry(alternatives, context, index + 1)
        }
        return result
    })
}

const get = (key, value) => Data.getValue(value, `value.${key}`)

const mapRec = (acc, target, index, fun, context, funString) => {
    const item = Data.getValue(target, `value.${index}`)
    if (funString) {
        let res = Data.createValue([], "list")
        res = Data.appendValue(res, "value", fun)
        res = Data.appendValue(res, "value", item)
        //console.log("ITEM", res)
        const newAcc = Data.appendValue(acc, "value", res)
        //console.log("ACC", newAcc)
        if (index === target.value.length - 1) return newAcc
        return mapRec(newAcc, target, index + 1, fun, context, funString)
    }
    const t = Type.getType(context, "map")
    let newContext = Data.setValue(context, "item", item, { parentType: t })
    return ifPromise(calc(fun, newContext), res => {
        const newAcc = Data.appendValue(acc, "value", res)
        if (index === target.value.length - 1) return newAcc
        return mapRec(newAcc, target, index + 1, fun, context, funString)
    })
}

const map = (expr, context, target) => {
    if (!Array.isArray(target.value) || target.value.length === 0) return target
    const fun = Data.getValue(expr, "value.2")
    const t = Type.getKeyType("value.2", expr)
    const acc = Data.createValue([], "list")
    return mapRec(acc, target, 0, fun, context, Type.is(t, "string"))
}
const filRec = (acc, target, index, fun, context) => {
    const item = Data.getValue(target, `value.${index}`)
    let t = Type.getType(context, "map")
    let newContext = Data.setValue(context, "item", item, { parentType: t })
    return ifPromise(calc(fun, newContext), res => {
        const newAcc = res.value ? Data.appendValue(acc, "value", item) : acc
        if (index === target.value.length - 1) return newAcc
        return filRec(newAcc, target, index + 1, fun, context)
    })
}
const filter = (expr, context, target) => {
    if (!Array.isArray(target.value) || target.value.length === 0) return target
    const fun = Data.getValue(expr, "value.2")
    const acc = Data.createValue([], "list")
    return filRec(acc, target, 0, fun, context)
}
const reduceRec = (acc, target, index, reducer, context) => {
    //if (index >= target.value.length) return acc
    const item = Data.getValue(target, `value.${index}`)
    let t = Type.getType(context, "map")
    let newContext = Data.setValue(context, "acc", acc, { parentType: t })
    t = Type.getType(newContext, "map")
    newContext = Data.setValue(newContext, "item", item, { parentType: t })
    //console.log("REDUCEREC", target, acc, item, context, newContext)
    return ifPromise(calc(reducer, newContext), newAcc => {
        //console.log("REDUCE ITER", newAcc)
        if (index === target.value.length - 1) return newAcc
        return reduceRec(newAcc, target, index + 1, reducer, context)
    })
}
const reduce = (expr, context, target) => {
    const init = Data.getValue(expr, "value.3")
    //console.log("REDUCE", target, init)
    if (!Array.isArray(target.value) || target.value.length === 0) return init
    const reducer = Data.getValue(expr, "value.2")
    return reduceRec(init, target, 0, reducer, context)
}
const functional = (expr, context, fun) => {
    const itemsExp = Data.getValue(expr, "value.1")
    const items = calc(itemsExp, context)
    return ifPromise(items, target => fun(expr, context, target))
}

const opCalc = (expr, context) => {
    //console.log("CALC", expr, context, Type.getKeyType("value.0", expr)?.is)
    //if (Type.getKeyType("value.0", expr)?.is !== "oper") return expr
    const op = Data.get(expr, "value.0")
    //if (op === "=") console.log("OP", expr, context)
    switch (op) {
        case "map": {
            return functional(expr, context, map)
        }
        case "fil": {
            return functional(expr, context, filter)
        }
        case "red": {
            return functional(expr, context, reduce)
        }
        case "if": {
            //console.log(expr)
            return ifPromise(calc(get(1, expr), context), result => {
                if (!result.value) {
                    if (expr.value.length === 4) return ifPromise(calc(get(3, expr), context))
                    return Data.createValue(null)
                }
                return ifPromise(calc(get(2, expr), context))
            })
        }
        case "??": {
            return fallbackTry(expr, context, 1)
        }
        default:
            break
    }

    return ifPromiseArray(
        (Array.isArray(expr?.value)
            ? expr?.value?.map((e, i) => (i == 0 ? null : calc(get(i, expr), context))).slice(1)
            : []) ?? [],
        args => switchOp(op, args, context)
    )
}

//let n = 0
//let n1 = 0
//let cached = 0
//let keys = {}
const $calc = Symbol("calc")

const getDepsRec = (expr, acc) => {
    //if(Array.isArray())
    switch (expr[0]) {
        case "f": {
            if (!expr[1]) return acc
            const toks = expr[1].split(".")
            if (toks[0] === "state" && toks[1]) return acc.add(`state.${toks[1]}`)
            if (toks[0]) return acc.add(toks[0])
            return acc
        }
        case "get": {
            if (!expr[1] || !expr[2]) return acc
            if (Array.isArray(expr[2])) {
                const toks = expr[1].split(".")
                let a = acc
                if (toks[0] === "state" && toks[1]) a = acc.add(`state.${toks[1]}`)
                else if (toks[0]) a = acc.add(toks[0])
                return getDepsRec(expr[2], a)
            } else {
                const toks = `${expr[1]}.${expr[2]}`.split(".")
                if (toks[0] === "state" && toks[1]) return acc.add(`state.${toks[1]}`)
                if (toks[0]) return acc.add(toks[0])
                return acc
            }
        }
        default: {
            //console.log(expr)
            return expr
                .slice(1)
                .reduce((a, item) => (Array.isArray(item) ? getDepsRec(item, a) : a), acc)
        }
    }
}
export const getDeps = expr => {
    if (!Array.isArray(expr.value)) return []
    return Array.from(getDepsRec(expr.value, new Set()))
}
const cache = new Map()

const calc = (expr, context) => {
    //n += 1
    //console.log("N=", n)
    if (!Array.isArray(expr.value) || Type.getKeyType("value.0", expr)?.is !== "oper") return expr

    //n1 += 1
    const key = JSON.stringify(expr.value)
    const deps = getDeps(expr)
    //keys[key] = deps
    //console.log("N1=", n1, "KEYS", Object.keys(keys).length, "CACHED", cached, keys)
    let c = cache.get(key)
    if (!c) {
        c = {}
        cache.set(key, c)
    }
    let c1 = c
    const same = deps.reduce((acc, item) => {
        if (c1 === null) return false
        const toks = item.split(".")
        const o = toks.length === 2 ? context.state?.[toks[1]] : context[toks[0]]
        if (o === null) {
            c1 = null
            return false
        }
        let citem = c1[item]
        if (!citem) {
            citem = {}
            c1[item] = citem
        }
        if (typeof o === "object") {
            if (!citem.o) citem.o = new WeakMap()
            let c2 = citem.o.get(o)
            if (!c2) {
                c2 = {}
                //console.log(o)
                citem.o.set(o, c2)
                c1 = c2
                return false
            }
            c1 = c2
            return acc
        } else {
            if (!citem.s) citem.s = {}
            if (!citem.s[o]) {
                citem.s[o] = {}
                c1 = citem.s[o]
                return false
            }
            c1 = citem.s[o]
            return acc
        }
    }, true)
    //let cres = null
    if (same && c1[$calc]) {
        //cached += 1
        //console.log("CACHED", cached, expr, context)
        //if (expr.value?.[0] === "is") console.log("IS CACHED", expr, context, deps, c, c1[$calc])
        //cres = c1[$calc]
        return c1[$calc]
    }
    //const ret = getCached(key, expr, context)
    //if (ret) return ret
    return ifPromise(opCalc(expr, context), res => {
        const t = Type.getType(res)
        if (Data.validate(res, t)) {
            if (c1) {
                c1[$calc] = res
                //if (expr.value?.[0] === "is") console.log("IS CACHE1", expr, context, res)
            }
            //context[$calc][key] = res
            //console.log(context)
            return res
        }
        const ret = Data.convert(res, t)
        //console.log("CONVERTED", res, ret)
        if (c1) {
            c1[$calc] = ret
            //if (expr.value?.[0] === "is") console.log("IS CACHE2", expr, context, ret)
        }
        //if (cres) console.log(expr?.value?.[0], expr, context, cres, ret)
        //context[$calc][key] = ret
        //console.log(context)
        return ret
    })
}
export const calcTag = (expr, context) => {
    //console.log("CALCTAG", expr, context)
    //if (expr?.value?.data?.name) console.trace("HERE")
    if (Type.getKeyType("value.0", expr)?.is !== "oper") {
        let t = Type.getKeyType("value", expr)
        const unionType = Type.unionType(t)
        const typeName = unionType ?? t?.is
        switch (typeName) {
            case "string":
                return expr.value
            case "img": {
                return { entity: expr.value, entityInfo: t }
            }
            default: {
                if (expr?.value?.data) {
                    const nameType = Type.getKeyType("value.data.name", expr)
                    const realType = Type.realType(nameType)
                    //console.log("DATAELEMENT", expr, nameType, realType)
                    if (realType === "op")
                        return calcTag(Data.createValue(expr.value.data.name, nameType), context)
                    return expr.value
                }
                //console.log("CALCTAG OTHER/UNKNOWN", t, unionType, expr)
                return [null, null]
            }
        }
    }
    const op = Data.get(expr, "value.0")
    //if (!expr || !Array.isArray(expr) || expr.length < 2) return [null, null]
    switch (op) {
        case "map": {
            return ifPromise(functional(expr, context, map), result => {
                //console.log("CALCTAG MAPARRAY", expr, context, result)
                const ret =
                    result.value?.map((item, i) => {
                        const t = Type.getKeyType(`value.${i}`, result)
                        if (Type.is(t, "list") && item.length === 2) {
                            if (typeof item[0] === "string") {
                                if (typeof item[1] === "string")
                                    return { element: item[0], data: item[1] }
                                return {
                                    element: item[0],
                                    data: Data.getValue(result, `value.${i}.1`),
                                }
                            }
                            if (!item[0]) return { entity: item[1] }
                        }
                        /*return t.is === "stringList"
                            ? item[0]
                                ? { element: item[0], content: item[1] }
                                : { entity: item[1] }
                            :*/
                        return { value: item }
                    }) ?? []
                //console.log("CALCTAG MAPRESULT", ret)
                return ret
            })
        }
        case "if": {
            //console.log("IF", expr, context)
            return ifPromise(calc(get(1, expr), context), result => {
                //console.log("IF", result)
                if (!result?.value) {
                    if (expr.value.length === 4) return calcTag(get(3, expr), context)
                    return null
                }
                const ret = calcTag(get(2, expr), context)
                return ret
            })
        }
        case "??": {
            return ifPromise(fallbackTry(expr, context, 1), value => value.value)
            //return ifPromise(fallback(expr.slice(1), context), value => value.value)
        }
        default:
            break
    }
    return ifPromiseArray(
        expr.value.map((e, i) => (i == 0 ? null : calc(get(i, expr), context))).slice(1),
        args => {
            //console.log("CALCTAG OP", op, args)

            switch (op) {
                case "f": {
                    // FIELD
                    if (!args[0]?.value) return Data.createValue()
                    //const value = getValue(contextValue, args[0].value)

                    //console.log(args, context)
                    const toks = args[0]?.value?.split(".")
                    //if (!toks) return Data.createValue()
                    //console.log(expr, context, args)
                    const entityName = toks[0]
                    const entity = context?.[entityName]
                    const entityInfo = context._e?.[entityName]
                        ? Type.getType(entity, context._e?.[entityName])
                        : Type.getType(entity)
                    if (!entityInfo) return null
                    //console.log(toks, entity, entityInfo)

                    if (toks.length === 1) {
                        if (entityInfo.is === "list") {
                            return (
                                entity?.map(e => ({
                                    entity: e,
                                })) ?? []
                            )
                        }
                        return { entity, entityInfo }
                    }

                    const all = toks[toks.length - 1] === "_all_"
                    const rest = toks[toks.length - 1] === "_rest_"
                    //if (rest) console.log(entityInfo)
                    const field =
                        all || rest
                            ? toks.slice(1, toks.length - 1).join(".")
                            : toks.slice(1).join(".")

                    if (field) {
                        if (all) {
                            const type = field
                                ? Type.getKeyType(field, entity, entityInfo)
                                : entityInfo
                            return {
                                entity,
                                entityInfo,
                                fields: type?.[$keyOrder].map(key => `${field}.${key}`),
                            }
                        }
                        if (rest) {
                            const type = field
                                ? Type.getKeyType(field, entity, entityInfo)
                                : entityInfo
                            return {
                                entity,
                                entityInfo,
                                fields: type?.[$keyOrder]
                                    .filter(key => type.keys[key]?.keyType === "dynamic")
                                    .map(key => `${field}.${key}`),
                            }
                        }
                        return { entity, entityInfo, fields: [field] }
                    } else {
                        if (all) return { entity, entityInfo, fields: entityInfo?.[$keyOrder] }
                        if (rest)
                            return {
                                entity,
                                entityInfo,
                                fields: entityInfo?.[$keyOrder].filter(
                                    key => entityInfo.keys[key]?.keyType === "dynamic"
                                ),
                            }
                        const t = Type.unionType(entityInfo) ?? entityInfo.is
                        if (t === "string") return { value: entity }
                        return { entity, entityInfo }
                    }
                }
                default: {
                    //return ifPromise(calc(expr, context).value, value => ({ value }))
                    //console.log("CALCTAGDEFAULT", op, args)
                    return ifPromise(switchOp(op, args, context), value => {
                        //console.log("CALCTAGRESULT", expr, value)
                        return calcTag(value, context)
                    })
                }
            }
        }
    )
}
export default calc
//calc(expr, context)
