import React from "react"
import { Entity, LazyImage, useSubscription } from "lib"
//import Container from "./Container"
import Portal from "./Portal"
import Element from "../Element"
import Carousel from "./Carousel"
import Navigator from "./Navigator"
import Track from "./Track"
/*
const TrackInner = ({info, context, state, dispatch, args, imgProps}) => {
    const newInfo = React.useMemo(() => ({
	...info, context, state, dispatch
    }), [info, context, state, dispatch])
    return info.value.map((src, i) => (
	<LazyImage key={i} src={src} {...args} {...imgProps} />
    ))

}
const CarouselInner = ({info, context, state, dispatch, args, imgProps}) => {
    const newInfo = React.useMemo(() => ({
	...info, context, state, dispatch
    }), [info, context, state, dispatch])
    console.log(newInfo)
    return (
	<>
	    <div carousel-inner="">
		<Track info={newInfo}>{(context, state, dispatch) => (
		    <TrackInner info={info} context={context} state={state} dispatch={dispatch} args={args} imgProps={imgProps}/>
		)}
		</Track>
		
	    </div>
	    <Element element="Arrows"
		     context={context}
		     state={state}
		     dispatch={dispatch}
		     next={newInfo.context.carousel.next} prev={newInfo.context.carousel.prev}/>
	    <Navigator info={newInfo}/>
	</>
    )
}*/

const ImageList = ({ info, path, nodePath, state, dispatch, lightbox, ...args }) => {
    //console.log("IMAGE LIST", info, args)
    const [lb, setLB] = React.useState()
    //const carouselInfo = info.typeInfo._carousel
    let val = info.value
    /*const localDispatch = React.useCallback(action => {
        console.log(action)
        }, [])*/
    useSubscription("closeLightbox", () => {
        setLB(false)
    })
    const lightboxType = React.useMemo(() => {
        if (!lightbox) return null
        return Entity.getKeyType(
            "lightbox",
            info.attrs,
            Entity.getType(info.attrs, {
                is: "map",
                values: "exprValExt",
            })
        )
    }, [lightbox, info.attrs])
    const handleLightbox = React.useCallback(() => {
        setLB(true)
    }, [])
    const imgProps = React.useMemo(() => {
        if (!lightbox) return {}
        return { onClick: handleLightbox }
    }, [lightbox, handleLightbox])

    const ref = React.useRef()
    /*React.useEffect(() => {
	if(info.args.display!=="embed") return
	if(!ref.current) return
	
	}, [info])*/
    
    if (!val || val.length === 0) return null
    
    //console.log("IMAGELIST", newInfo)

    /*if (carouselInfo) {
        return (
            <Container tag={Carousel} info={info} {...args}>
                <Track>
                    {val.map((img, i) => (
                        <LazyImage key={i} src={img} data-index={i} {...imgProps} />
                    ))}
                </Track>
                {carouselInfo.arrows !== false && <Arrows />}
                {carouselInfo.navigator !== false && <Navigator />}
            </Container>
        )
    }*/
    //console.log(info)
    if(info.args.display==="embed") {
        
	const maxH = typeof window==="undefined"?0:
              info.value
	    .map(img => img.w?window.innerWidth*img.h/img.w:window.innerHeight)
	      .reduce((acc, v) => v>acc?v:acc,0)
	const f = typeof window==="undefined"
              ?1
            :(maxH<window.innerHeight*0.7
	      ?1
	      :Math.round(maxH/(window.innerHeight*0.7)))
	const classes = f>1?`product-carousel visible-${f}`:"product-carousel"
	//const style = {"--n-visible":f}
	//console.log(info.value, window.innerWidth, maxH, f)
	return (
	    <div ref={ref}>
		<Carousel info={info} className={classes}>{(context, state, dispatch) => {
		//const newInfo = {...info, context}
		const newInfo = { context}
		const attrs = {
		    next: context.carousel.next,
		    prev:context.carousel.prev,
		    _e: {
			next: {is: "fun"},
			prev: {is: "fun"}
		    }
		}
		return (
		<>
		    <div className="carousel-inner">
			<Track info={newInfo}>
			    {() => (
				<>
				    {info.value.map((src, i) => (
					<LazyImage key={i} src={src} {...args} {...imgProps} />
				    ))}
				</>
			    )}
			</Track>
			<Element element="Arrows" attrs={attrs}/>
		    </div>
		    <Navigator info={newInfo}/>
		</>
		)}}
	    </Carousel>
		</div>
	    
	)
	/*return (
	    <Carousel info={info} className="product-carousel">{

		(context, state, dispatch) => (
		    <CarouselInner info={info} context={context} state={state} dispatch={dispatch} args={args} imgProps={imgProps}/>
		)}
	    </Carousel>
	)*/
    }
    return (
        <>
            {info.value.map((src, i) => (
                <LazyImage key={i} src={src} {...args} {...imgProps} />
            ))}
            {lb && (
                <Portal>
                    <Element
                        info={info}
                        entity={lightbox}
                        entityType={lightboxType}
                        state={state}
                        dispatch={dispatch}
                    />
                </Portal>
            )}
        </>
    )
}
export default ImageList
