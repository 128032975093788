import Type from "../type"
import Data from "../data"
import customOps from "conf/ops"

const plus_int_int = (a1, a2) => ({ ...a1, value: a1.value + a2.value })
const plus_int_number = (a1, a2, t1, t2) => ({
    ...a2,
    value: a1.value * 10 ** (t2.decimals ?? 0) + a2.value,
})
const plus_number_int = (a1, a2, t1) => ({
    ...a1,
    value: a1.value + a2.value * 10 ** (t1.decimals ?? 0),
})
const plus_number_number = (a1, a2, t1, t2) => {
    const d1 = t1.decimals ?? 0
    const d2 = t2.decimals ?? 0
    if (d1 >= d2) return { ...a1, value: a1.value + a2.value * 10 ** (d1 - d2) }
    return { ...a2, value: a1.value * 10 ** (d2 - d1) + a2.value }
}
const plus_other_other = (a1, a2) => ({ ...a1, value: a1.value + a2.value })

const plus_string_string = (arg1, arg2) => ({ ...arg1, value: arg1.value + arg2.value })
const plus_list_list = (arg1, arg2) => ({ ...arg1, value: [...arg1.value, ...arg2.value] })
const plus_list_other = (arg1, arg2) => ({
    ...arg1,
    value: [...arg1.value, arg2.value],
    _e: {
        value: {
            ...(arg1._e.value ?? {}),
            [`${arg1.value.length}`]: arg2._e.value,
        },
    },
})
const plus_other_list = (arg1, arg2) => ({
    ...arg2,
    value: [arg1.value, ...arg2.value],
    _e: {
        value: {
            ...(arg2._e.value ?? {}),
            [`${arg2.value.length}`]: arg1._e.value,
        },
    },
})

const plus_map_map = (arg1, arg2) => ({ ...arg1, value: { ...arg1.value, ...arg2.value } })

const minus_int_int = (a1, a2) => ({ ...a1, value: a1.value - a2.value })
const minus_int_number = (a1, a2, t1, t2) => ({
    ...a2,
    value: a1.value * 10 ** (t2.decimals ?? 0) - a2.value,
})
const minus_number_int = (a1, a2, t1) => ({
    ...a1,
    value: a1.value - a2.value * 10 ** (t1.decimals ?? 0),
})
const minus_number_number = (a1, a2, t1, t2) => {
    const d1 = t1.decimals ?? 0
    const d2 = t2.decimals ?? 0
    if (d1 >= d2) return { ...a1, value: a1.value - a2.value * 10 ** (d1 - d2) }
    return { ...a2, value: a1.value * 10 ** (d2 - d1) - a2.value }
}

const times_int_int = (a1, a2) => ({ ...a1, value: a1.value * a2.value })
const times_number_number = (a1, a2, t1, t2) => ({
    ...a1,
    value: a1.value * a2.value,
    _e: {
        value: {
            ...(a1._e._value ?? {}),
            decimals: (t1.decimals ?? 0) + (t2.decimals ?? 0),
        },
    },
})
const div_int_int = (a1, a2) => {
    if (a2.value === 0) return Data.createValue()
    const value = a1.value / a2.value
    //console.log("DIV", a1, a2, value)
    if (Math.round(value) === value) return { ...a1, value }
    return {
        ...a1,
        value,
        _e: {
            value: {
                ...(a1?._e?._value ?? {}),
                is: "number",
            },
        },
    }
}
const div_number_number = (a1, a2, t1, t2) => {
    if (a2.value === 0) return Data.createValue()
    const d1 = t1.decimals ?? 0
    const d2 = t2.decimals ?? 0
    const d = d1 - d2
    // (700 / 10**2) / (100 / 10**0) =
    //console.log("DIV", a1, a2, d1, d2, d)
    return {
        ...a1,
        value: a1.value / a2.value,
        _e: {
            value: {
                ...(a1._e._value ?? {}),
                decimals: d,
            },
        },
    }
}
const pow_int_int = (a1, a2) => ({ ...a1, value: a1.value ** a2.value })
const pow_number_number = (a1, a2, t1) => ({
    ...a1,
    value: a1.value ** a2.value,
    _e: {
        value: {
            ...(a1._e._value ?? {}),
            decimals: t1.decimals ?? 0,
        },
    },
})

const ops2 = {
    plus_int_int,
    plus_int_number,
    plus_number_int,
    plus_number_number,
    plus_string_string,
    plus_list_list,
    plus_list_other,
    plus_other_list,
    plus_map_map,
    plus_other_other,

    minus_int_int,
    minus_int_number,
    minus_number_int,
    minus_number_number,

    times_int_int,
    times_number_number,
    times_int_number: times_number_number,
    times_number_int: times_number_number,

    div_int_int,
    div_number_number,
    div_int_number: div_number_number,
    div_number_int: div_number_number,

    pow_int_int,
    pow_number_number,
    pow_int_number: pow_number_number,
    pow_number_int: pow_number_number,
    ...customOps,
}

const types = ["int", "number", "string", "list", "map"]
const doop = (args, op) => {
    if (args.length === 0) return Data.createValue()
    const ret = args.slice(1).reduce((acc, arg) => {
        const t1 = Type.getKeyType("value", acc)
        const t2 = Type.getKeyType("value", arg)
        const t1Types = [Type.realType(t1), ...types.filter(t => Type.is(t1, t))]
        const t2Types = [Type.realType(t2), ...types.filter(t => Type.is(t2, t))]
        for (const tx of t1Types) {
            for (const ty of t2Types) {
                const fun = `${op}_${tx}_${ty}`
                if (ops2[fun]) {
                    //console.log("FUN", fun, acc, arg)
                    return ops2[fun](acc, arg, t1, t2)
                }
            }
        }
        for (const tx of t1Types) {
            const fun = `${op}_${tx}_other`
            //console.log("FUN", fun, acc, arg)
            if (ops2[fun]) return ops2[fun](acc, arg, t1, t2)
        }
        for (const ty of t2Types) {
            const fun = `${op}_other_${ty}`
            //console.log("FUN", fun, acc, arg)
            if (ops2[fun]) return ops2[fun](acc, arg, t1, t2)
        }
        const fun = `${op}_other_other`
        //console.log("FUN", fun, acc, arg, t1, t2)
        if (ops2[fun]) return ops2[fun](acc, arg, t1, t2)
        console.log(
            "OP FUN NOT FOUND",
            `${op}_${Type.realType(t1)}_${Type.realType(t2)}`,
            acc,
            arg,
            t1,
            t2
        )
        return Data.createValue()
    }, args[0])
    //console.log("OP", op, args, ret)
    return ret
}

const plus = args => doop(args, "plus")
const minus = args => doop(args, "minus")
const times = args => doop(args, "times")
const div = args => doop(args, "div")
const pow = args => doop(args, "pow")
export default { plus, minus, times, div, pow }
