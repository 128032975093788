import React from "react"
import Element from "../Element"
import { $keyOrder } from "../../entity/symbols"

const ElemWidget = ({ info, state, dispatch }) => {
    const element = info.value
    if (!element?.element?.name) {
        console.log("Element widget: no element selected.", info)
        return null
    }
    //console.log(element, info, args)
    const dynamic = info.typeInfo[$keyOrder].filter(
        key => info.typeInfo.keys[key].keyType === "dynamic"
    )
    if (dynamic.length > 0)
        return (
            <Element
                element={element.element.name}
                attrs={element.attrs}
                info={info}
                state={state}
                dispatch={dispatch}
            >
                {dynamic.map((key, i) => (
                    <Element
                        key={i}
                        fieldName={key}
                        info={info}
                        state={state}
                        dispatch={dispatch}
                    />
                ))}
            </Element>
        )

    return (
        <Element
            element={element.element.name}
            attrs={element.attrs}
            info={info}
            state={state}
            dispatch={dispatch}
        />
    )
}
export default ElemWidget
