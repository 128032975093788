import React from "react"
import { useWS, useSubscription } from "lib"
import useScroll from "../../hooks/useScroll"
//import Admin from "../Admin"
//import Shell from "../Shell"
import SEO from "./SEO"

const scrollCutoff = 250
const useScrolled = () => {
    const rif = React.useRef()
    const state = React.useRef({ scroll: -1, direction: 0, tweens: [] })
    useScroll(scroll => {
        const currentScroll = state.current.scroll < scrollCutoff
        const newScroll = scroll < scrollCutoff
        const currentDirection = state.current.direction
        const direction = scroll >= state.current.scroll
        let tweens = []
        if (currentScroll !== newScroll) tweens.push("tween-scroll")
        if (currentDirection !== direction) tweens.push("tween-direction")
        state.current = { scroll, direction, tweens }
        //console.log(state.current, scroll, direction)
        if (currentScroll === newScroll && currentDirection === direction) return
        if (rif.current) return
        rif.current = requestAnimationFrame(() => {
            rif.current = 0
            const el = document.body //document.getElementById("root")
            el.classList.remove(
                "top",
                "not-top",
                "scroll-down",
                "scroll-up",
                "tween-scroll",
                "tween-direction"
            )
            el.classList.add(
                state.current.scroll < scrollCutoff ? "top" : "not-top",
                state.current.direction ? "scroll-down" : "scroll-up",
                ...state.current.tweens
            )
        })
    })
}
const useTween = (node, location) => {
    //console.log(location)
    const prev = React.useRef()
    React.useEffect(() => {
        const type = node?.type
        if (!type) return
        const p = prev.current ?? type
        const tween = `tween-${p}-${type}`
        prev.current = type
        const el = document.getElementById("root")
        if (!el) return
        const toks = location?.sanitized.split("/")
        const nodeClass = location?.sanitized === "/" ? "node-homepage" : `node${toks.join("-")}`
        el.className = `site site-${type} site-prev-${p} ${tween} ${nodeClass}`
    }, [node?.type, location?.sanitized])
}
/*
function gtag() {
    dataLayer.push(arguments);
}

const init = () => {
  window.dataLayer = window.dataLayer || [];
  gtag('js', new Date());

  gtag('config', 'G-J0NNTJF8VD');
} */

const NodeManager = ({ location }) => {
    useWS()
    useScrolled()
    const [node] = useSubscription("node")
    //const user = useSubscription("user")
    useTween(node, location)
    /*const pathname = location.pathname
    const initialized = React.useRef()
    React.useEffect(() => {
	if(!pathname) return
	if(!initialized.current) {
	    console.log("first send", pathname)
	    //init()
	    initialized.current = true
	    return
	}
	console.log("send", pathname)
	try {
        //window.gtag('set', 'page_path', pathname);
        //window.gtag('event', 'page_view');
        window.gtag("config", "G-J0NNTJF8VD", {
            page_title: pathname,
            page_path: pathname,
        })
        }catch(e){
        console.log("err", e)
        //console.log(window.gtag)
        }
    }, [pathname])*/
    if (location.sanitized === "/shell") {
        return null
        //return <Shell user={user} node={node} pathname={location.pathname} />
    }
    //console.log(node)
    return (
        <>
            <SEO location={location} node={node} language={location.language} />
        </>
    )
}

export default React.memo(NodeManager)
