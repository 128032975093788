import React from "react"
import ReactDOM from "react-dom"
import { useUnmounted } from "lib"

const Portal = ({ domRef, info, state, dispatch, children }) => {
    const localRef = React.useRef()
    const ref = domRef ?? localRef
    const [, triggerRender] = React.useState(0)
    const unmounted = useUnmounted()
    React.useEffect(() => {
        if (unmounted.current) return
        const div = document.createElement("div")
        //div.classList.add("tooltip")
        document.body.appendChild(div)
        ref.current = div
        triggerRender(state => (state + 1) % 100)
        return () => {
            //console.log("effect out", div)
            ref.current = null
            if (div) div.remove()
        }
    }, [ref, unmounted])
    //console.log(args)
    if (!ref.current) return null
    //console.log("RENDER", tooltipRef.current, tooltip, tooltipInfo)
    return ReactDOM.createPortal(
        typeof children === "function" ? children(info.context, state, dispatch) : children,
        ref.current
    )
}
export default Portal
