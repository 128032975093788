export const ifPromise = (p, cb) => {
    if (p instanceof Promise)
        return new Promise((resolve, reject) =>
            p
                .then(res => resolve(cb ? cb(res) : res))
                .catch(e => {
                    console.log(e)
                    reject(e.message)
                })
        )
    return cb ? cb(p) : p
}
export const ifPromiseArray = (result, cb) => {
    let pIndexes = []
    const promises = result.filter((item, i) => {
        if (item instanceof Promise) {
            pIndexes.push(i)
            return true
        }
        return false
    })
    if (promises.length > 0) {
        return new Promise((resolve, reject) =>
            Promise.all(promises)
                .then(pResults => {
                    const ret = result.map((item, i) => {
                        const pIndex = pIndexes.indexOf(i)
                        if (pIndex < 0) return item
                        return pResults[pIndex]
                    })
                    if (cb) return resolve(cb(ret))
                    return resolve(ret)
                })
                .catch(e => reject(e))
        )
    }
    if (cb) return cb(result)
    return result
}
