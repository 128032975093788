import React from "react"
import useUnmounted from "./useUnmounted"

export const useDeferred = v => {
    const [, triggerRender] = React.useState(0)
    const unmounted = useUnmounted()
    const waitFor = React.useCallback(
        async p => {
            await p
            if (unmounted.current) return
            triggerRender(v => (v + 1) % 100)
        },
        [unmounted]
    )

    if (v instanceof Promise) {
        waitFor(v)
        return null
    }
    return v
}
export const useGetDeferred = () => {
    const [, triggerRender] = React.useState(0)
    const unmounted = useUnmounted()
    const waitFor = React.useCallback(
        async (p, cb) => {
            const val = await p
            if (unmounted.current) return
            if (cb) cb(val)
            triggerRender(v => (v + 1) % 100)
        },
        [unmounted]
    )
    const maybeDeferred = React.useCallback(
        (v, cb) => {
            if (v instanceof Promise) {
                waitFor(v, cb)
                return null
            }
            return v
        },
        [waitFor]
    )

    return maybeDeferred
}

export const useDeferredLoader = () => {
    const [renderID, triggerRender] = React.useState(0)
    const refs = React.useRef({})
    const unmounted = useUnmounted()
    const waitFor = React.useCallback(
        async tag => {
            refs.current[tag] = await refs.current[tag]
            if (unmounted.current) return
            //console.log("LOADID wait1", refs.current[tag])
            if (refs.current[tag] instanceof Promise) {
                //console.log("LOADID wait2", refs.current[tag])
                return waitFor(tag)
            }
            //console.log("LOADID wait3", refs.current[tag])
            triggerRender(v => (v + 1) % 100)
        },
        [unmounted]
    )
    const load = React.useCallback(
        (tag, loadFun) => {
            if (refs.current[tag] === null) {
                //console.log("LOADID1 null", tag)
                return null
            }
            if (!refs.current[tag]) {
                refs.current[tag] = loadFun()
                //console.log("LOADID2", refs.current[tag])

                if (refs.current[tag] instanceof Promise) {
                    waitFor(tag)
                    return null
                }
            }
            if (refs.current[tag] instanceof Promise) {
                //console.log("LOADID3", refs.current[tag])
                return null
            }
            //console.log("LOADID4", refs.current[tag])
            return refs.current[tag]
        },
        [waitFor]
    )
    return [load, renderID]
}
