import axios from "axios"
import calc from "lib/entity/calc"
import Type from "../../entity/type"
import Data from "../../entity/data"

const submit = (attr, attrInfo, args, info, dispatch, multiple) => {
    const name = attr[1]
    //const t = Type.getKeyType(2, attr, attrInfo)
    const value = calc(Data.getValue(attr, 2, { parentType: attrInfo }), info.context)
    const valueType = Type.realType(Type.getKeyType("value", value))
    const submission = {
        type: "submission",
        kind: name,
        payload: value.value,
        _e: { payload: { is: valueType } },
    }
    //console.log("SUBMIT", attr, attrInfo, args, info, name, value)
    return axios
        .post("form", { submission })
        .then(response => {
            console.log(response)
            if (multiple) return info
        })
        .catch(error => {
            console.log(error)
            if (multiple) return info
        })
}
export default submit
