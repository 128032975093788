import React from "react"
import { useUnmounted } from "lib"

const config = { childList: true }
const Track = ({ domRef, info, context, state, dispatch, children, ...args }) => {
    const unmounted = useUnmounted()
    let con = context ?? info?.context
    const carousel = con?.carousel
    const { update, n, itemWidth } = carousel
    const localRef = React.useRef()
    const ref = domRef ?? localRef
    const childList = React.useCallback(() => {
        const n = ref.current?.children?.length
        update({ n })
    }, [ref, update])
    React.useEffect(() => {
        childList()
        const observer = new MutationObserver(childList)
        observer.observe(ref.current, config)
        return () => observer.disconnect()
    }, [childList, ref])

    const running = React.useRef()
    const timeout = 200
    const carouselStep = React.useCallback(
        (n, start, dir, end) => {
            running.current = true
            let steps = 1
            if (dir === "left") {
                steps = (end + n - start) % n
                const doStep = k => {
                    const stepStart = (start + k) % n
                    for (let i = 0; i < n; i++) {
                        const el = ref.current?.children?.item((stepStart + i) % n)
                        if (el) el.style.order = i
                    }
                    if (ref.current) {
                        ref.current.style.transition = "none"
                        ref.current.style.transform = "none"
                    }
                    requestAnimationFrame(() => {
                        if (unmounted.current) return
                        const timing =
                            k === 0
                                ? k === steps - 1
                                    ? "ease-in-out"
                                    : "ease-in"
                                : k === steps - 1
                                ? "ease-out"
                                : "linear"
                        if (ref.current) {
                            ref.current.style.transition = `transform ${timeout / 1000}s ${timing}`
                            ref.current.style.transform = `translate3d(-${100 / n}%, 0, 0)`
                        }
                    })
                    if (k < steps - 1) {
                        setTimeout(() => {
                            if (unmounted.current) return
                            doStep(k + 1)
                        }, timeout)
                    }
                }
                doStep(0)
            } else {
                steps = (start + n - end) % n
                const doStep = k => {
                    const stepStart = (start + n - k) % n
                    for (let i = 0; i < n; i++) {
                        const el = ref?.current?.children.item((stepStart - 1 + i + n) % n)
                        if (el) el.style.order = i
                    }
                    if (ref.current) {
                        ref.current.style.transition = "none"
                        ref.current.style.transform = `translate3d(-${100 / n}%, 0, 0)`
                    }
                    requestAnimationFrame(() => {
                        if (unmounted.current) return
                        const timing =
                            k === 0
                                ? k === steps - 1
                                    ? "ease-in-out"
                                    : "ease-in"
                                : k === steps - 1
                                ? "ease-out"
                                : "linear"
                        if (ref.current) {
                            ref.current.style.transition = `transform ${timeout / 1000}s ${timing}`
                            ref.current.style.transform = "none"
                        }
                    })
                    if (k < steps - 1) {
                        setTimeout(() => {
                            if (unmounted.current) return
                            doStep(k + 1)
                        }, timeout)
                    }
                }
                doStep(0)
            }
            setTimeout(() => {
                running.current = false
            }, timeout * steps)
        },
        [running, ref, unmounted]
    )
    const tryStep = React.useCallback(
        (n, start, dir, end) => {
            if (unmounted.current) return
            if (running.current) {
                return requestAnimationFrame(() => {
                    tryStep(n, start, dir, end)
                })
            }
            carouselStep(n, start, dir, end)
        },
        [carouselStep, unmounted]
    )
    React.useEffect(() => {
        if (!carousel) return
        if (carousel.p === carousel.a) return
        requestAnimationFrame(() => {
            tryStep(carousel.n, carousel.p, carousel.dir, carousel.a)
        })
    }, [ref, carousel, tryStep])
    React.useEffect(() => {
        ref.current.style.width = `${n * itemWidth}px`
    }, [ref, n, itemWidth])
    const props = React.useMemo(
        () => ({
            track: "",
        }),
        []
    )

    return (
        <div ref={ref} {...props} {...args}>
            {children(con, state, dispatch)}
        </div>
    )
}
export default Track
