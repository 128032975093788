import { publish } from "lib/hooks/useSubscription"
import calc from "lib/entity/calc"
//import Type from "../../entity/type"
import Data from "../../entity/data"

const send = (attr, attrInfo, args, info, dispatch, multiple) => {
    const message = attr[1]
    if (!message) return
    //const t = Type.getKeyType(2, attr, attrInfo)
    const value =
        attr[2] === "_"
            ? args[0].target.value
            : calc(Data.getValue(attr, 2, { parentType: attrInfo }), info.context).value
    //console.log("ACTION SEND MESSAGE", message, value)
    //const value = attr[2] //new Date()
    publish(message, value)
    if (multiple) return info
}
export default send
