import C from "../conf"
import Type from "./type"
import Data from "./data"
import { load, loadById } from "./load"

import { fold } from "./fold"

const getTypeField = (info, field, options) => {
    if (options?.language) {
        let ret = info?._i18n?.[options.language]?.[field]
        if (ret) return ret
    }
    return info[field]
}
const getId = e => e?._id
const getFieldTranslations = (entity, fieldName) => {
    if (!C.LANGUAGES || C.LANGUAGES.length < 2) return null
    const typeInfo = Type.getKeyType(fieldName, entity)
    if (typeInfo?._t === false) return null
    const defaultLanguage = entity?._lang ?? C.LANGUAGES[0]
    return C.LANGUAGES.reduce(
        (acc, language) =>
            Data.get(entity, fieldName, { defaultLanguage, language }) ? [...acc, language] : acc,
        []
    )
}
export default {
    //classes,
    typeFromTypeDef: Type.typeFromTypeDef,
    getType: Type.getType,
    getKeyType: Type.getKeyType,
    getEntityTypes: Type.getEntityTypes,

    impl: Data.impl,
    typeImpl: Type.impl,
    is: Data.is,
    typeIs: Type.is,
    getCollection: Data.getCollection,
    getSettings: Type.getSettings,
    hasField: Type.hasField,
    keyOrder: Type.keyOrder,
    unionType: Type.unionType,
    realType: Type.realType,

    set: Data.set,
    unset: Data.unset,
    get: Data.get,
    format: Data.format,
    formatField: Data.formatField,
    getTypeField,
    getId,
    getPath: Data.getPath,
    getFieldTranslations,
    validate: Data.validate,
    convert: Data.convert,

    create: Data.create,
    load,
    loadById,

    getFieldOrder: Data.getFieldOrder,

    fold,

    createValue: Data.createValue,
    getValue: Data.getValue,
    setValue: Data.setValue,
    appendValue: Data.appendValue,
}
