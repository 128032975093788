/**
 * window.requestIdleCallback()
 * version 0.0.0
 * Browser Compatibility:
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback#browser_compatibility
 */
if (!window.requestIdleCallback) {
    window.requestIdleCallback = function (callback, options) {
        const relaxation = 1
        const timeout = options?.timeout ?? relaxation
        const start = performance.now()
        return setTimeout(function () {
            callback({
                get didTimeout() {
                    return options?.timeout
                        ? false
                        : performance.now() - start - relaxation > timeout
                },
                timeRemaining: () => {
                    return Math.max(0, relaxation + (performance.now() - start))
                },
            })
        }, relaxation)
    }
}

/**
 * window.cancelIdleCallback()
 * version 0.0.0
 * Browser Compatibility:
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/cancelIdleCallback#browser_compatibility
 */
if (!window.cancelIdleCallback) {
    window.cancelIdleCallback = id => {
        clearTimeout(id)
    }
}

/**
 * window.requestAnimationFrame()
 * version 0.0.0
 * Browser Compatibility:
 * https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame#browser_compatibility
 */
if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = callback =>
        window.setTimeout(() => {
            callback(Date.now())
        }, 1000 / 60)
}

/**
 * window.cancelAnimationFrame()
 * version 0.0.0
 * Browser Compatibility:
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/cancelAnimationFrame#browser_compatibility
 */
if (!window.cancelAnimationFrame) {
    window.cancelAnimationFrame = id => {
        clearTimeout(id)
    }
}
window.lazySizesConfig = window.lazySizesConfig || {}
window.lazySizesConfig.init = false
window.lazySizesConfig.srcAttr = "data-img"
//window.lazySizesConfig.preloadAfterLoad = true
document.addEventListener("lazybeforeunveil", e => {
    var bg = e.target.getAttribute("data-bg")
    if (bg) {
        e.target.style.backgroundImage = "url(" + e.target.getAttribute("data-img") + ")"
        e.preventDefault()
    }
})

const root = document.getElementById("root")
const childrenLength = root.children.length
window.isHydrating = childrenLength > 0
window.isLoading = true
//console.log(window.isHydrating)
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true
const mq = W => {
    if (W <= 400) return "xss"
    if (W <= 600) return "xs"
    if (W <= 900) return "sm"
    if (W <= 1200) return "md"
    if (W <= 1600) return "lg"
    return "xl"
}
const W = window.innerWidth
const H = window.innerHeight
document.body.classList.add(
    mq(W),
    W > H ? "landscape" : "portrait",
    W < 400 || H < 400 ? "mobile" : "not-mobile"
)

document.body.style.setProperty("--screen-ratio", W / H)
const checkLocale = locale => {
    if (
        !(
            "Intl" in window &&
            "Collator" in window.Intl &&
            "supportedLocalesOf" in window.Intl.Collator &&
            window.Intl.Collator.supportedLocalesOf(locale).length === 1 &&
            "DateTimeFormat" in window.Intl &&
            "supportedLocalesOf" in window.Intl.DateTimeFormat &&
            window.Intl.DateTimeFormat.supportedLocalesOf(locale).length === 1 &&
            "NumberFormat" in window.Intl &&
            "supportedLocalesOf" in window.Intl.NumberFormat &&
            window.Intl.NumberFormat.supportedLocalesOf(locale).length === 1
        )
    )
        return false
    return true
}
export const checkFeatures = () => {
    /*console.log(
        "intersection",
        "IntersectionObserver" in window,
        "IntersectionObserverEntry" in window,
        "intersectionRatio" in window.IntersectionObserverEntry.prototype,
        "ResizeObserver" in window
        )*/
    const features = []
    if (
        !(
            "IntersectionObserver" in window &&
            "IntersectionObserverEntry" in window &&
            "intersectionRatio" in window.IntersectionObserverEntry.prototype
        )
    )
        features.push("intersection")
    if (!("ResizeObserver" in window)) features.push("resize")
    if (!checkLocale("ro")) features.push("ro")
    return features
}
export const loadPolyfill = (features, done) => {
    let finished = false

    const handleLoad = () => {
        console.log("handleload")
        if (!finished) {
            finished = true
            done()
        }
    }

    const handleReadyStateChange = () => {
        console.log("handleready", script.readyState)
        if (!finished) {
            if (script.readyState === "complete") {
                handleLoad()
            }
        }
    }

    const handleError = () => {
        console.log("error")

        if (!finished) {
            finished = true
            done()
        }
    }
    const script = document.createElement("script")
    script.onload = handleLoad
    script.type = "text/javascript"
    script.onreadystatechange = handleReadyStateChange
    script.onerror = handleError
    script.src = `/polyfills/${features.join("-")}.js`
    document.head.appendChild(script)
    console.log("loading")
}
export const getRoot = () => [root, childrenLength]
//export default init
