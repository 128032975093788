import Bugsnag from "@bugsnag/js"
import React from "react"
import site from "conf/site"
//import { Query } from "../query"
//import { updateStyle } from "../sw/initSW"
import { publish } from "../hooks/useSubscription"
export const updateStyle = style => {
    //console.log("update style", style)
    const links = document.getElementsByTagName("link")
    for (let i in links) {
        const link = links[i]
        if (
            !link ||
            !link.getAttribute ||
            link.getAttribute("rel") !== "stylesheet" ||
            link.href.indexOf(site.host) < 0
        )
            continue

        if (link.href.indexOf("css/site") >= 0 && style) link.href = style
    }
}

//const reCSS = /\.css$/

let ws
let tok
const process = (ws, data) => {
    try {
        const command = JSON.parse(data.data)
        //console.log(command)
        switch (command.cmd) {
            //case "style-path":
            //      updatePageStyle(command.stylePath)
            //    break
            case "update": {
                if (command.type === "data") {
                    //console.log("WS CALL REFRESH")
                    //if (window.location.hostname === "localhost") Query.refresh()
                    const sw = navigator?.serviceWorker?.controller
                    if (sw)
                        sw.postMessage({
                            type: "APP_UPDATED",
                            meta: "client-window",
                            payload: window.location.pathname,
                        })
                }
                if (command.type === "style") {
                    updateStyle(`${site.protocol}://${site.host}${command.style}`)
                    setTimeout(() => {
                        publish("STYLE_UPDATE", command.style)
                    }, 1000)
                }
                //else {
                //}
                break
            }
            case "noop": {
                //console.log("noop")
                /*const { cmd, ...state } = command
                //console.log(window.appVersion, state)
                if (!window.appVersion) window.appVersion = state
                else {
                    if (window.appVersion.v !== state.v) {
                        if (window.appVersion.data !== state.data) {
                            //console.log("refresh data")
                            Query.refresh()
                        }
                        if (window.appVersion.style !== state.style) {
                            if (state.stylePath) updatePageStyle(state.stylePath)
                            else ws.send(JSON.stringify({ cmd: "get-style-path" }))

                            //console.log("refresh style")
                        }
                        if (window.appVersion.app !== state.app) {
                            //console.log("refresh app")
                        }
                        window.appVersion = state
                    }
                    //console.log("noop", cmd)
                }*/
                break
            }
            default:
                console.log("Unknown command", command)
        }
    } catch (e) {
        console.log(e)
    }
}
let initialDelay = 5000
let delay = initialDelay
const connect = () => {
    if (ws) return
    //console.log("connect ws")

    let args
    if (tok) {
        args = ["token", tok]
    }
    try {
        //console.log("WS connect")
        ws = new WebSocket(`wss://${site.host}/wss`, args)
    } catch (e) {
        Bugsnag.notify(e)
        return
    }
    if (!ws) {
        setTimeout(() => {
            connect()
        }, delay)
        delay = delay * 2
        return
    }
    ws.onopen = () => {
        delay = initialDelay
        //console.log("onopen")
        try {
            if (ws) ws.send(JSON.stringify({ cmd: "noop" }))
        } catch (e) {
            console.log(e)
        }
    }

    ws.onmessage = data => {
        process(ws, data)
    }
    ws.onclose = () => {
        //console.log(e)
        ws = null
        //console.log("close ws")
        setTimeout(() => {
            connect(tok)
        }, initialDelay)
    }
    ws.onerror = () => {
        //console.log(e)
        ws = null
        //console.log("error ws")
        setTimeout(() => {
            connect(tok)
        }, delay)
        delay = delay * 2
    }
}
const disconnect = () => {
    if (ws) {
        ws.close()
        ws = null
    }
}
export const authWS = token => {
    tok = token
    disconnect()
    //connect()
}
export const logoutWS = () => {
    tok = null
    disconnect()
    //connect()
}

export const useWS = () => {
    React.useEffect(() => {
        //if (typeof window === "undefined") return
        connect()
    }, [])
}
