import Entity from "../entity"

/*const setRec = (state, path, i, value) => ({
    ...(state ?? {}),
    [path[i]]: i < path.length - 1 ? setRec(state[path[i]], path, i + 1, value) : value,
})*/
const reducer = (state, action) => {
    //console.log(state, action)
    switch (action.type) {
        case "CHANGE": {
            const ret = Entity.setValue(state, action.field, action.value, {
                entityInfo: Entity.getType(state, { is: "map", values: "exprVal" }),
            })
            //console.log(state, action, ret)
            return ret
            //return setRec(state, action.field.split("."), 0, action.value)
        }
        case "JOIN": {
            return {
                ...state,
                ...action.newState,
                _e: {
                    ...(state._e ?? {}),
                    ...(action.newState._e ?? {}),
                },
            }
        }
        default:
            return state
    }
}
export default reducer
