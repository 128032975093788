const subscriptions = {
    cart: "cart",
    openCart: "openCart",
    addToCart: "addToCart",
    removeFromCart: "removeFromCart",
    openURL: "openURL",
    openMenu: "openMenu",
    //checkout: "checkout",
}
export default subscriptions
