
const isSSR = typeof window === "undefined"
const notBot = !isSSR && "onscroll" in window && !/(gle|ing)bot/.test(navigator.userAgent)
const isBot = !notBot
const isBrowser = !isSSR && notBot
const DPR = isBrowser ? window.devicePixelRatio : 1
const isHydrating = () => isBrowser && window.isHydrating
/*
const  gtag = () => {
    window.dataLayer.push(arguments);
}
window.dataLayer = window.dataLayer || [];
  gtag('js', new Date());

  gtag('config', 'G-J0NNTJF8VD');
  */
export { isSSR, notBot, isBot, isBrowser, DPR, isHydrating }
