import calc from "lib/entity/calc"
import { ifPromise } from "lib/entity/util"
import Type from "../../entity/type"
import Data from "../../entity/data"

const updateRec = (target, targetType, index, cond, condInfo, info) => {
    if (index >= (target.value?.length ?? 0)) return -1
    const item = target.value[index]
    const itemType = Type.getKeyType(`value.${index}`, target)
    const context = {
        ...info.context,
        target: target.value,
        targetItem: item,
        _e: {
            ...info.context?._e,
            target: targetType,
            targetItem: itemType,
        },
    }
    return ifPromise(calc(Data.createValue(cond, condInfo), context), condRes => {
        //console.log("COND", cond, info, condRes)
        if (!condRes.value) return updateRec(target, targetType, index + 1, cond, condInfo, info)
        return index
    })
}
const upsert = (attr, attrInfo, arg, info, dispatch, multiple) => {
    //console.log("UPSERT", attr, attrInfo, arg, info)
    const contextType = Type.getType(info.context, "map")
    //const targetInfo = Type.getKeyType("1", attr, attrInfo)
    return ifPromise(
        calc(Data.getValue(attr, 1, { parentType: attrInfo }), info.context),
        target => {
            const targetType = Type.typeFromTypeDef({
                ...target._e.value,
                label: "Target",
            })
            const condInfo = Type.getKeyType("2", attr, attrInfo)
            return ifPromise(updateRec(target, targetType, 0, attr[2], condInfo, info), index => {
                //console.log("UPSERT INDEX", index)
                //const t = Type.getKeyType(3, attr, attrInfo)
                const val =
                    attr[3] === "_"
                        ? arg
                        : calc(Data.getValue(attr, 3, { parentType: attrInfo }), info.context)
                //console.log("UPSERT VAL", attr, val, arg)
                let field = null
                if (attr[1]?.[0] === "f")
                    field = calc(
                        Data.getValue(attr, "1.1", { parentType: attrInfo }),
                        info.context
                    ).value
                if (!field) return null
                if (field.startsWith("state.")) field = field.slice(6)
                let value = val
                //console.log(target)
                if (index < 0) {
                    // append
                    //value = Data.getValue(target, "value", {parentType:targetType})
                    const t = target?._e?.value ?? { is: "list" }
                    if (val._e?._value) {
                        const index = (target?.value ?? []).length
                        t[`${index}`] = val._e.value
                    }
                    value = Data.createValue([...(target?.value ?? []), val.value], t)
                } else {
                    // update
                    field = `${field}.${index}`
                }
                //console.log("ACTION UPSERT", field, value)
                dispatch({ type: "CHANGE", field, value })
                if (multiple) {
                    return {
                        ...info,
                        context: Data.setValue(info.context, `state.${field}`, value, {
                            parentType: contextType,
                        }),
                    }
                }
            })
        }
    )
}
export default upsert
