import React, { Suspense } from "react"
const AuthImpl = React.lazy(() => import("./AuthImpl"))

const Auth = () => {
    const [first, setFirst] = React.useState(true)
    React.useEffect(() => {
        if (window.location.pathname === "/shell") {
            setFirst(false)
            return
        }
        setTimeout(() => {
            setFirst(false)
        }, 2000)
    }, [])
    if (first) return null

    return (
        <Suspense fallback={null}>
            <AuthImpl />
        </Suspense>
    )
}
export default Auth
