import calc from "lib/entity/calc"
import Type from "../../entity/type"
import Data from "../../entity/data"

const setState = (attr, attrInfo, args, info, dispatch, multiple) => {
    //console.log("SET STATE", attr, attrInfo, args, info) //attr, attrInfo, args, info,
    const field = calc(Data.getValue(attr, 1, { parentType: attrInfo }), info.context).value
    const contextType = Type.getType(info.context, "map")
    //const t = Type.getKeyType(2, attr, attrInfo)
    let value =
        attr[2] === "_"
            ? args[0].target.value
            : calc(Data.getValue(attr, 2, { parentType: attrInfo }), info.context)
    //console.log(attr, value)
    if (attr[2] === "_") {
        const fieldType = Type.getKeyType(`state.${field}`, info.context, contextType)
        const realType = Type.realType(fieldType)
        //const contextType =
        //const v = Data.getValue(info.context, `state.${field}`, {parentType: contextType})
        //console.log(realType, fieldType, value)
        switch (realType) {
            case "string":
                value = Data.createValue(value, "string")
                break
            case "number":
                value = parseFloat(value)
                if (isNaN(value)) value = Data.createValue(undefined, "number")
                else value = Data.createValue(value, "number")
                break
            case "int":
                value = parseInt(value)
                if (isNaN(value)) value = Data.createValue(undefined, "int")
                else value = Data.createValue(value, "int")
                break
            default:
                value = Data.createValue()
                break
        }
    }

    if (value?._e?.value?.icon) console.trace("ACTION", attr, attrInfo, args, info, field, value)
    //console.log("ACTION SET STATE", field, value) //attr, attrInfo, args, info,
    dispatch({ type: "CHANGE", field, value })
    if (multiple)
        return {
            ...info,
            context: Data.setValue(info.context, `state.${field}`, value, {
                parentType: contextType,
            }),
        }
}
export default setState
