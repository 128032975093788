import React from "react"
import { Entity, useQuery, useDeferred } from "lib"
//import Element from "../Element"
import { useHistory } from "lib/history"
import calc from "../../entity/calc"
import { ifPromise, ifPromiseArray } from "lib/entity/util"

const useSelection = info => {
    //const [pager, setPager] = React.useState({page: 0})
    //console.log(args, info)
    const args = info.args
    const history = useHistory()
    const search = history.location.search
    const page = args?.attrs?.pager
	  ?parseInt(search?.[args.attrs.pager]??'1')
	  :0

    const value = info.value
    //console.log(info.value, info)
    //console.log(args, value)
    const condAttrs = React.useMemo(() => {
        const cond = value?.cond ?? args?.cond
        const condInfo = Entity.getKeyType("cond", value, info.typeInfo)
        //if (info.typeInfo?.is === "select") cond = value?.cond
        //console.log(cond)
        if (!cond) return []
        return cond.map((a, i) => {
            const vInfo = Entity.getKeyType(`${i}.v`, cond, condInfo)
            const realType = Entity.realType(vInfo)
            //console.log(a, realType)
            if (realType === "op") {
                return ifPromise(
                    calc(Entity.getValue(cond, `${i}.v`, { parentType: condInfo }), info.context),
                    v => ({
                        ...a,
                        v: v.value,
                        _e: { ...a._e, v: v._e._value },
                    })
                )
            }
            return a
        })
        //const res = parseAttr(args.cond)
    }, [args, value, info])
    const cond = useDeferred(ifPromiseArray(condAttrs))
    //console.log(cond, condAttrs) //, condAttrs, args.cond, value.cond, info.context)
    const query = React.useMemo(() => {

        let limit = parseInt(args?.attrs?.limit ?? args?.limit ?? value?.limit ?? "0")
        const type = args?.entityType ?? value?.entityType
        const collection =
            args.collection ?? (type ? Entity.typeFromTypeDef(type)?.collection : null)
        if (!collection) return null

	if (args?.attrs?.pageSize) {
	    limit = args.attrs.pageSize
	    //skip = pager.page * limit
	}
	let order = null
	if (args?.attrs?.order) {
	    order = args.attrs.order.split(',')
		.map(item => {
		    const toks = item.trim().split(":").map(t => t.trim())
		    return [toks[0], parseInt(toks[1])]
		})
		.reduce((acc, item) => {
		    acc[item[0]] = item[1]
		    return acc
		}, {})
	    //console.log(order)
	}
	//console.log(args)
        return {
            collection,
            query: {
                ...(type ? { type } : {}),
                ...(cond?.reduce((acc, a) => (a?.a ? { ...acc, [a.a]: a.v } : acc), {}) ?? {}),
            },
	    ...(page>0 ? {skip: (page-1)*limit} : {}),
            ...(limit ? { limit } : {}),
	    ...(order ? {sort: order}:{})
        }
    }, [args, value, cond, page])

    //console.log("Q", query, args)
    return useQuery(query)
}

const Select = ({ info, children }) => {
    //const result = useSelection(args)
    const args = info.args
    const [results, status, total] = useSelection(info)
    //console.log("SELECT", info, results, status, total)
    const newInfo = React.useMemo(() => {
        const context = {
            ...(info.context ?? {}),
            selectTotal: total ?? 0,
            selectStatus: status,
            selectResults: results ?? [],
            _e: {
                ...(info.context._e ?? {}),
                selectTotal: "int",
                selectStatus: "string",
                selectResults: { is: "list", items: args?.entityType ?? info.value?.entityType },
            },
        }
        return { ...info, context }
    }, [info, results, status, total, args])
    //console.log(children, results)
    /*if (!children) {
        const keys = Entity.keyOrder(info.typeInfo)?.filter(
            key => info.typeInfo.keys[key].keyType === "dynamic"
        )
        //console.log(keys, newInfo, args)
        //console.log(info, element, props, itemAttrs)
        return (
            <>
                {keys?.map((key, i) => (
                    <Element
                        key={i}
                        info={newInfo}
                        state={state}
                        dispatch={dispatch}
                        fieldName={key}
                        attrs={args}
                    />
                ))}
            </>
        )
    }*/
    //console.log(info, results)
    return children(newInfo)
}

export default Select
