import React, { useLayoutEffect, useEffect, useRef, useCallback } from "react"
//import NProgress from "nprogress"
import { useHistory } from "lib/history"

export const pagerToFirstPage = search =>
    search
        ? Object.keys(search)
              .filter(key => key !== "page")
              .reduce((acc, key) => ({ ...acc, [key]: search[key] }), {})
        : null

const Pager = ({ domRef, _e, info, state, dispatch, total, pageSize, children, ...rest }) => {
    //const page, total, pageSize, onChange, scrollTo, progress
    const ref = useRef()
    const first = useRef(true)
    const clicked = useRef()
    const history = useHistory()
    const search = history.location.search
    //const {}
    //const total = info?.args?.attrs?.total
    const page = parseInt(search?.page ?? '1')
    //const pageSize = info?.args?.attrs?.pageSize
    const pages = Math.ceil(total / pageSize)
    //const {context} = info
    //console.log(search, info, total, pageSize, pages, search, rest)
    useEffect(() => {
	if (page>pages)
	    goto(1)
    }, [page, pages])
    useEffect(() => {
        if (first.current) {
            first.current = false
            return
        }
        if (!clicked.current) return
        clicked.current = false
        const el = ref.current //document.querySelector(scrollTo)
        if (el) {
            const pos = el.getBoundingClientRect().top
            if (pos < 0) el.scrollIntoView({ behavior: "smooth", block: "start" })
        }
    }, [page])

    /*useLayoutEffect(() => {
        //if (progress) NProgress.configure({ parent: "#pager" })
    }, [])*/

    //console.log(total, pageSize, op)

    const goto = useCallback(
        page => {
            clicked.current = true
            if (page === 1) history.pushSearch(pagerToFirstPage(search))
            else history.pushSearch({ ...search, page })
            //onChange(page)
        },
        [search, history]
    )
    const firstPage = useCallback(() => goto(1), [goto])
    const nextPage = useCallback(() => goto(page + 1), [goto, page])
    const prevPage = useCallback(() => goto(page - 1), [goto, page])
    const lastPage = useCallback(() => goto(pages), [goto, pages])

    //if (pages < 2) return null
    //const pagerProps = {}
    //if (progress) pagerProps.id = "pager"
    return (
	<div ref={ref} {...rest}>
            {pages>1 && (
		<div className="pager" >
		    <div className="pager-in">
			<div className="arrows">
			    {page > 1 && (
				<div className="arrow" onClick={firstPage}>
				    1
				</div>
			    )}
			    {page > 2 && (
				<div className="arrow" onClick={firstPage}>
				    &laquo;
				</div>
			    )}
			    {page > 2 && (
				<div className="arrow" onClick={prevPage}>
				    &lsaquo;
				</div>
			    )}
			    <div className="current-page">{page}</div>
			    
			    {page < pages - 1 && (
				<div className="arrow" onClick={nextPage}>
				    &rsaquo;
				</div>
			    )}
			    {page < pages - 1 && (
				<div className="arrow" onClick={lastPage}>
				    &raquo;
				</div>
			    )}
			    {page < pages && (
				<div className="arrow" onClick={lastPage}>
				    {pages}
				</div>
			    )}
			</div>
			<hr />
			<div className="current">
			    <div className="current-inner">
				Pagina <span className="p">{page}</span> din {pages}
			    </div>
			</div>
			<hr />
		    </div>
		</div>
	    )}
	    {children(info.context, state, dispatch)}
            {pages>1 && (
		<div className="pager" >
		    <div className="pager-in">
			<div className="arrows">
			    {page > 1 && (
				<div className="arrow" onClick={firstPage}>
				    1
				</div>
			    )}
			    {page > 2 && (
				<div className="arrow" onClick={firstPage}>
				    &laquo;
				</div>
			    )}
			    {page > 2 && (
				<div className="arrow" onClick={prevPage}>
				    &lsaquo;
				</div>
			    )}
			    <div className="current-page">{page}</div>
			    
			    {page < pages - 1 && (
				<div className="arrow" onClick={nextPage}>
				    &rsaquo;
				</div>
			    )}
			    {page < pages - 1 && (
				<div className="arrow" onClick={lastPage}>
				    &raquo;
				</div>
			    )}
			    {page < pages && (
				<div className="arrow" onClick={lastPage}>
				    {pages}
				</div>
			    )}
			</div>
			<hr />
			<div className="current">
			    <div className="current-inner">
				Pagina <span className="p">{page}</span> din {pages}
			    </div>
			</div>
			<hr />
		    </div>
		</div>
	    )}
	</div>
    )
}

export default Pager
