import React from "react"
import ReactDOM from "react-dom"
import { useUnmounted } from "lib"
import Element from "./Element"
import Type from "../entity/type"

const Tooltip = ({ info, tooltip, tooltipInfo, tooltipRef }) => {
    const [, triggerRender] = React.useState(0)
    const unmounted = useUnmounted()
    React.useEffect(() => {
        if (unmounted.current) return
        const div = document.createElement("div")
        div.classList.add("tooltip")
        document.body.appendChild(div)
        tooltipRef.current = div
        triggerRender(state => (state + 1) % 100)
        return () => {
            //console.log("effect out", div)
            tooltipRef.current = null
            if (div) div.remove()
        }
    }, [tooltipRef, unmounted])
    if (!tooltipRef.current) return null
    //console.log("RENDER", tooltipRef.current, tooltip, tooltipInfo)
    return ReactDOM.createPortal(
        <Element info={info} entity={tooltip} entityInfo={tooltipInfo} />,
        tooltipRef.current
    )
}

const useTooltip = (info, attrs) => {
    const tooltipRef = React.useRef()
    const [hover, setHover] = React.useState()
    const hasTooltip = !!attrs.tooltip
    const tooltipInfo = React.useMemo(() => {
        if (!hasTooltip) return null
        const attrsInfo = Type.getType(attrs, "map")
        return Type.getKeyType("tooltip", attrs, attrsInfo)
    }, [hasTooltip, attrs])
    const Tag = React.useMemo(
        () =>
            hover ? (
                <Tooltip
                    info={info}
                    tooltip={attrs.tooltip}
                    tooltipInfo={tooltipInfo}
                    tooltipRef={tooltipRef}
                />
            ) : null,
        [hover, attrs.tooltip, tooltipInfo, info]
    )

    const onMouseMove = React.useCallback(
        e => {
            //console.log("MOVE", e.clientY, e.clientX, tooltipRef.current)
            if (!tooltipRef.current) return
            tooltipRef.current.style.top = `${e.clientY}px`
            tooltipRef.current.style.left = `${e.clientX}px`
            if (attrs.onMouseMove) attrs.onMouseMove(e)
        },
        [attrs]
    )
    const onMouseOver = React.useCallback(
        e => {
            //console.log("HOVER")
            setHover(true)
            if (attrs.onMouseOver) attrs.onMouseOver(e)
        },
        [attrs]
    )
    const onMouseOut = React.useCallback(
        e => {
            //console.log("HOUT")
            setHover(false)
            if (attrs.onMouseOut) attrs.onMouseOut(e)
        },
        [attrs]
    )
    if (!hasTooltip) return [Tag, null]
    //console.log(attrs.tooltip)
    return [Tag, { onMouseOver, onMouseOut, onMouseMove }]
}
export default useTooltip
