import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import { checkFeatures, loadPolyfill, getRoot } from "./init"
import React from "react"
import ReactDOM from "react-dom"
import * as serviceWorker from "registerServiceWorker"
import { LanguageManager } from "lib" //, Query
import Location from "lib/app/Location"
import Site from "lib/app/Site"
import Auth from "lib/auth/Auth"
import { HelmetProvider } from "react-helmet-async"
//import { initSW } from "lib/sw/initSW"
const startBugsnag = async () => {
    Bugsnag.start({
        apiKey: "6a86efd8f278072120dde2c2064b34b6",
        plugins: [new BugsnagPluginReact(React)],

        beforeSend: function (report) {
            const params = new URLSearchParams(window.location.search)
            const fbclidCheck = params.get("fbclid")
            if (
                fbclidCheck &&
                report.errorClass === "TypeError" &&
                report.errorMessage === "Illegal invocation"
            ) {
                report.ignore()
            }
        },
    })
}
const main = () => {
    startBugsnag()
    //console.log("main")
    const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React)
    const [root, childrenLength] = getRoot()
    //console.log("children:", childrenLength, module.hot)
    const render = childrenLength === 0 || module.hot ? ReactDOM.render : ReactDOM.hydrate
    //initSW()
    render(
        <ErrorBoundary>
            <HelmetProvider>
                <LanguageManager>
                    <Site />
                    <Location />
                    <Auth />
                </LanguageManager>
            </HelmetProvider>
        </ErrorBoundary>,
        root,
        () => {
            window.isHydrating = false
            window.isLoading = false
            document.body.classList.add("site-loaded")
        }
    )
}
const features = checkFeatures()
if (features.length > 0) {
    loadPolyfill(features, main)
} else main()

serviceWorker.register()
