import axios from "axios"
//import { requestIdleCallback } from "../util/idle"

const isSSR = typeof window === "undefined"
let storage = {
    queries: {},
    promises: {},
    //t: {},
    willFetch: false,
    active: [],
    //qid: 1,
    //hydrated: false,
}
export const getStorage = () => storage

export const hashstr = s => {
    let hash = 0
    if (s.length === 0) return hash
    for (let i = 0; i < s.length; i++) {
        let char = s.charCodeAt(i)
        hash = (hash << 5) - hash + char
        hash = hash & hash // Convert to 32bit integer
    }
    return "h" + hash
}
export const registerQuery = (q, cb) => {
    const key = hashstr(JSON.stringify(q))
    //storage.qid += 1
    //storage.active.push({ qid: storage.qid, key, q, cb })
    storage.active.push({ key, q, cb })
    //console.log("register", key, q)
    return key
    //storage.qid
}
export const unregisterQuery = key => {
    //console.log("unregister", key, storage.active)
    storage.active = storage.active.filter(item => item.key !== key)
    //console.log("AFTER unregister -> ", storage.active)
}

export const clearCache = () => {
    if (isSSR) return
    window.dataStore = {}
    //console.log("CLEAR CACHE")
    /*for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i)
      if (isHash(key)) sessionStorage.removeItem(key)
      }*/
}

export const select = async q => {
    //console.log("select", q)
    const headers = typeof window === "undefined" ? {} : { "Orig-Page": window.location.pathname }
    const data = await axios.post("find", { params: { options: [q] } }, { headers })
    //console.log(data?.data?.[0])
    return data?.data?.[0] ?? null
    //if (data && data.data && data.data.length > 0) return parseBuffer(data.data[0])
    //return null
}
export const refresh = key => {
    //console.log("REFRESH", key, storage.active)
    //sessionStorage.clear()
    if (isSSR) return
    if (key && !window.dataStore[key]) return

    //clearCache()
    if (!storage.willFetch) {
        storage.willFetch = true
        requestIdleCallback(fetch)
    }
    if (key) {
        const active = storage.active.filter(item => item.key === key)
        if (active.length > 0) {
            storage.queries[key] = active[0].q
            if (!storage.promises[key]) storage.promises[key] = []
            storage.promises[key].push(active[0].cb)
        } else {
            storage.queries[key] = window.dataStore[key].query
        }
    } else {
        storage.active.forEach(item => {
            storage.queries[item.key] = item.q
            if (!storage.promises[item.key]) storage.promises[item.key] = []
            storage.promises[item.key].push(item.cb)
        })
        Object.keys(window.dataStore).forEach(key => {
            if (storage.active.filter(item => item.key === key).length > 0) return
            storage.queries[key] = window.dataStore[key].query
        })
    }
}
export const refreshed = () => {
    if (isSSR) return
    storage.active.forEach(item => {
        item.cb(window.dataStore[item.key])
    })
}

export const executePrio = q => {
    //console.log("PRIO", q)
    const key = hashstr(JSON.stringify(q))
    if (!isSSR) {
        if (window.dataStore[key]) {
            return window.dataStore[key]
        }
    } else if (global.store[key]) {
        return global.store[key]
    }
    return new Promise((resolve, reject) =>
        select(q)
            .then(results => {
                //console.log("PRIO RES", q, results)
                if (!isSSR) {
                    window.dataStore[key] = results
                } else global.store[key] = results
                return resolve(results)
            })
            .catch(e => {
                reject(e)
            })
    )
}

export const execute = q => {
    if(!q) return null
    const key = hashstr(JSON.stringify(q))
    try {
        if (!isSSR) {
            if (window.dataStore[key]) {
                return window.dataStore[key]
            }
        } else if (global.store[key]) {
            return global.store[key]
        }
    } catch (e) {
        console.log(e)
    }
    storage.queries[key] = q
    if (!storage.willFetch) {
        storage.willFetch = true
        if (!isSSR) requestIdleCallback(fetch)
    }
    const promise = new Promise(resolve => {
        const cb = data => resolve(data)
        if (!storage.promises[key]) storage.promises[key] = []
        storage.promises[key].push(cb)
    })
    if (isSSR) {
        global.storePromises.push(promise)
    }

    return promise
}
const schedule = cb => {
    if (typeof window === "undefined") return cb()

    const scheduleFrame = frameStart => {
        const delta = performance.now() - frameStart
        if (delta > 6) {
            requestAnimationFrame(scheduleFrame)
            return
        }
        cb()
    }
    requestIdleCallback(
        () => {
            requestAnimationFrame(() => {
                scheduleFrame()
            })
        },
        { timeout: Math.floor(Math.random() * Math.floor(200)) }
    )
}

export const fetch = async () => {
    if (!storage.willFetch) return
    //console.log("FETCH", Object.keys(storage.queries).length, Object.keys(storage.queries))
    const queries = storage.queries
    const promises = storage.promises
    storage.queries = {}
    storage.promises = {}
    storage.willFetch = false

    let response
    const keys = Object.keys(queries)
    const options = Object.values(queries)
    /*keys.map(key => {
        const { noCache, ...q } = queries[key]
        //return serialize(q)
        return q
    })*/
    try {
        const headers =
            typeof window === "undefined" ? {} : { "Orig-Page": window.location.pathname }
        response = await axios.post("find", { params: { options } }, { headers })
    } catch (e) {
        //console.log("useQuery, fetch:", e.message, JSON.stringify(options))
        for (const key of keys) {
            for (const p of promises[key]) {
                p(null)
            }
        }
        return
    }
    //console.log(response)
    if (response.status !== 200 || response.data.error) {
        for (const key of keys) {
            for (const p of promises[key]) {
                p(null)
            }
        }
        if (response.data.error) console.log("ERROR:", response.data.message, response)
        return
    }
    const responseData = response.data
    //if (typeof window !== "undefined") console.log(response.data)
    //console.log(response)
    for (const data of responseData) {
        //console.log(data, data.query)
        if (!data) continue
        const key = hashstr(JSON.stringify(data.query))
        if (!isSSR) {
            //if (!currentUser)
            window.dataStore[key] = data
        } else global.store[key] = data
        if (promises[key]) {
            for (const p of promises[key]) {
                schedule(() => {
                    p(data)
                })
            }
        }
    }
}

const updateMany = (update, cb = null) => {
    let collection = update.collection || "node"
    return axios
        .put(`datamany/${collection}`, update)
        .then(response => {
            clearCache()
            if (cb) cb(response)
        })
        .catch(error => console.log(error))
}

const update = (update, cb = null) => {
    //console.log(update)
    //console.log()
    let id
    if (update.id) id = update.id
    else {
        if (update._id) id = update._id
        //if (update._id && update._id instanceof ObjectId) id = update._id.toString()
        else return
    }
    let collection = update.collection || "node"
    return axios
        .put(`data/${collection}/${id}`, { update }) //: serialize(update) })
        .then(response => {
            clearCache()
            //console.log(response)
            if (cb) cb(response)
            return response
        })
        .catch(error => console.log(error))
}
const insert = (update, cb = null) => {
    let collection = update.collection || "node"
    return axios
        .post(`data/${collection}`, { update }) //: serialize(update) })
        .then(response => {
            clearCache()
            //console.log(response)
            if (cb) cb(response)
            return response
        })
        .catch(error => console.log(error))
}

const findFilesRecursive = (entity, files) => {
    if (!entity) return
    if (typeof entity !== "object") return

    if (entity.url) {
        files.push(entity.url)
        return
    }
    if (Array.isArray(entity)) {
        entity.forEach(item => findFilesRecursive(item, files))
        return
    }
    Object.keys(entity).forEach(key => findFilesRecursive(entity[key], files))
}
const findFiles = entity => {
    let files = []
    findFilesRecursive(entity, files)
    return files
}
const remove = (id, collection = "node", cb = null) =>
    axios
        .delete(`data/${collection}/${id}`)
        .then(response => {
            clearCache()
            if (cb) cb(response)
        })
        .catch(error => console.log(error))

export default {
    select,
    update,
    insert,
    updateMany,
    findFiles,
    refresh,
    fetch,
    execute,
    remove,
    refreshed,
}
