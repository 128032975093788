import React from "react"
import Portal from "./Portal"
import ItemTransition from "./ItemTransition"
import { useUnmounted } from "lib"

const Dialog = ({ open, className, ...args }) => {
    const unmounted = useUnmounted()
    const [localOpen, setLocalOpen] = React.useState(open)
    //console.log(open, args)
    React.useEffect(() => {
        if (open === localOpen) return
        const timeout = args.timeout ?? 1000
        if (open) {
            setLocalOpen(true)
            return
        }
        setTimeout(() => {
            if (unmounted.current) return
            setLocalOpen(false)
        }, timeout)
    }, [open, localOpen, unmounted, args.timeout])
    if (!localOpen) return null
    const dialogClass = className ?? "dialog"
    return (
        <Portal>
            <ItemTransition {...args} itemKey={open || "none"} className={dialogClass} />
        </Portal>
    )
}
//{children(info.context, state, dispatch)}</ItemTransition>
export default Dialog

/*import React from "react"
import { Modal, useSubscription, publish } from "lib"

const Dialog = ({ info, path, nodePath, children, ...args }) => {
    const [isOpen, setOpen] = React.useState()
    const { open, onClose, onExited, ...attrs } = args
    useSubscription("message", (topic, message) => {
        if (message === open) setOpen(true)
    })
    const handleClose = React.useCallback(() => {
        setOpen(false)
        if (onClose) publish("message", onClose)
    }, [onClose])
    const handleExited = React.useCallback(() => {
        setOpen(false)
        if (onExited) publish("message", onExited)
    }, [onExited])
    return (
        <Modal open={isOpen} onClose={handleClose} onExited={handleExited} {...attrs}>
            {children}
        </Modal>
    )
}
export default Dialog
*/
