import { getType } from "../type"
import { compose } from "../schema"
//import apply from "../apply"
import { $keyType } from "../symbols"

const unset = (parent, parentType, key) => {
    //console.log("LIST REMOVEKEY", key, typeof key, parent, parentType)
    if (!parent) return parent
    const value = parent.filter((item, k) => k !== key)
    return [value, [key]]
}

const set = (parent, parentType, key, type, value) => {
    //console.log("LIST SET", parent, parentType, key, typeof key, type, value)
    if (!/^\d+$/.test(key)) return null
    //return parent.map((item, k) => (k === key ? value : item))
    return [...(parent ?? []).slice(0, key), value, ...(parent ?? []).slice(key + 1)]
}

const buildPath = (parent, parentType, skey, path) => {
    //console.log("LIST BUILDPATH", key, parent, parentType)
    const key = parseInt(skey)
    //console.log(skey, key)
    if (isNaN(key)) return null

    let type = parent?.[$keyType]?.[key]
    //let type
    if (typeof type === "undefined") {
        type = keyType(parent, parentType, key)
        //console.log("LIST ITEM", parent, parentType, skey, type)
        if (!type) return null
        if (Array.isArray(parent)) {
            if (!parent?.[$keyType] && Object.isExtensible(parent)) {
                Object.defineProperty(parent, $keyType, { value: {} })
            }
            if (parent[$keyType]) parent[$keyType][key] = type
        }
    }
    if (!type) return null
    const value = parent?.[key]
    //if (type1 && type1 !== type) console.log(type1, type)
    //const type = keyType(parent, parentType, key)
    //console.log("LIST BUILDPATH TYPE", type)
    //if (!type) return null
    return [
        ...path,
        {
            parent,
            parentType,
            key, //: parseInt(key)
            type,
            value, //apply(, type, "value"),: parent?.[parseInt(key)]
        },
    ]
}
const keyType = (parent, parentType, key) => {
    if (!/^\d+$/.test(key)) return null
    let t = parentType.keys?.[key] ?? parentType.items ?? "t"
    t = typeof t === "string" ? { is: t } : t
    if (parentType[key]) t = compose(t, parentType[key])
    if (parent?.[key]?.type) {
        t = compose(t, { is: parent?.[key]?.type, ...(parent?.[key]?._c ?? {}) })
    }
    const ret = getType(parent?.[key], t)
    return ret
}
/*const keyType = (parent, parentType, key) => {
    if ((!parentType.items && !parentType.keys) || !/^\d+$/.test(key)) return null
    let t = parentType.keys?.[key] ?? parentType.items
    t = typeof t === "string" ? { is: t } : t
    if (parentType[key]) t = compose(t, parentType[key])
    const ret = getType(parent?.[key], t)
    return ret
}*/

const typeKeys = (o, type) => {
    //console.log("MAP TYPEKEYS", o, type)
    return type
}
export default {
    keyType,
    typeKeys,
    buildPath,
    set,
    unset,
}
