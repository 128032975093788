import React from "react"
import { Entity, Link } from "lib"

const LinkRenderer = ({ info, path, nodePath, state, dispatch, children, content, ...args }) => {
    const { linkActivate } = state ?? {}
    const handleActivate = React.useCallback(() => {
        linkActivate()
        return true
    }, [linkActivate])
    const { _e, ...rest } = args

    const props = state?.linkActivate ? { ...rest, onActivate: handleActivate } : rest
    const isLink = Entity.typeIs(info.typeInfo, "link") && !rest.to
    const typeProps = isLink ? { to: info.value?.path } : {}
    const label = isLink ? info.value?.label : ""
    //console.log(children, info, path, nodePath)
    return (
        <Link {...props} {...typeProps}>
            {label}
            {content}
            {children?.(info.context, state, dispatch)}
        </Link>
    )
}

export default LinkRenderer
