const umKinds = [
    { label: "Discret", value: "items" },
    { label: "Greutate", value: "weight" },
    { label: "Lungime", value: "length" },
]
const types = {
    // NODES
    /*page: {
        keys: {
            body: { is: "html", label: "Conținut" },
        },
    },*/
    post: {
        is: "node",
        label: "Postare",
        searchField: "title",
        keys: {
            title: { is: "text", label: "Titlu" },
            date: { is: "date", label: "Data" },
            banner: { is: "img", label: "Banner" },
            thumb: { is: "img", label: "Thumbnail" },
            body: { is: "html", label: "Continut" },
        },
    },
    category: {
        is: "node",
        label: "Categorie",
        searchField: "title",
        keys: {
            title: { is: "text", label: "Titlu" },
        },
        settings: { banner: { is: "img", label: "Imagine Banner" } },
    },
    product: {
        is: "node",
        label: "Produs",
        info: "Produs",
        searchField: "title",
        settings: {
            banner: { is: "img", label: "Imagine Banner" },
            promoted: { is: "bool", label: "Promovat" },
            order: { is: "int", label: "Ordine" },
        },
        keys: {
            title: { is: "text", label: "Nume" },
            cat: {
                is: "ref",
                ref: "category",
                label: "Categorie",
                cache: "title,path",
                renderer: "CategoryLink",
                _nowrap: true,
            },
            milk: { is: "text", label: "Produs din lapte de", values: ["Oaie", "Vacă"] },
            description: { is: "text", label: "Descriere", multiLine: true, rows: 3 },
            ingredients: { is: "text", label: "Ingrediente", multiLine: true, rows: 3 },
            nutrition: { is: "nutrition" },
            img: { is: "img", label: "Imagini" },
        },
    },
    variant: {
        is: "node",
        label: "Variantă",
        searchField: "title",
        keys: {
            title: { is: "text", label: "Nume" },
            img: { is: "img", label: "Imagini" },
            cat: {
                is: "ref",
                ref: "category",
                label: "Categorie",
                cache: "title,path",
                renderer: "CategoryLink",
                _nowrap: true,
            },
            prod: {
                is: "ref",
                ref: "product",
                label: "Produs",
                cache: "title,milk,path",
                renderer: "ProductLink",
                _nowrap: true,
            },
            weight: {
                is: "union",
                types: [
                    { is: "weight", label: "Greutate" },
                    { is: "estWeight", label: "Greutate estimativă" },
                ],
            },
            price: {
                is: "union",
                types: [
                    { is: "price", label: "Preț" },
                    { is: "unitPrice", label: "Preț unitar" },
                ],
            },
        },
        _closed: true,
    },

    // ENTITIES
    currency: {
        is: "entity",
        label: "Moneda",
        collection: "util",
        refOptions: { searchField: "name" },
        keys: {
            code: { is: "string", label: "Cod" },
            name: { is: "string", label: "Nume" },
        },
    },
    um: {
        is: "entity",
        label: "Unitate de măsură",
        collection: "util",
        refOptions: { searchField: "symbol" },
        keys: {
            kind: { is: "string", label: "Tip", values: umKinds },
            symbol: { is: "string", label: "Simbol" },
            factor: { is: "int", label: "Factor" },
        },
    },
    menu: {
        is: "entity",
        label: "Meniu",
        collection: "menu",
        _noCreate: true,
        keys: {
            label: { is: "text", label: "Nume" },
            items: { is: "menuItems", label: "Elemente" },
        },
    },
    menuItems: {
        label: "Element",
        is: "list",
        items: "menuItem",
    },
    menuItem: {
        label: "Element Meniu",
        is: "record",
        keys: {
            title: { is: "text", label: "Titlu" },
            p: { is: "text", label: "Cale" },
            items: { is: "menuItems", label: "Elemente" },
        },
    },

    // TYPES
    img: {
        icon: "filled/photo",
        settings: { lightbox: { is: "bool", label: "Lightbox" } },
        renderer: "ImageList",
	embedable: true
    },
    video_link: {
        label: "Video",
        icon: "filled/ondemand_video",
        classes: ["widget", "display"],
	is: "text",
	embedable: true,
	renderer: "Video",
    },
    link: {
        label: "Legătură",
        icon: "filled/link",
        dynamic: true,
        classes: ["widget"],
        is: "record",
        keys: {
            label: { is: "text", label: "Etichetă" },
            path: { is: "text", label: "Legătură" },
        },
        isBlock: true,
        renderer: "Link",
    },

    block: {
        label: "Container",
        icon: "faBoxOpen",
        is: "map",
        isBlock: true,
        classes: ["widget"],
    },
    button: {
        label: "Buton",
        icon: "outlined/smart_button",
        dynamic: true,
        is: "record",
        classes: ["widget"],
        keys: {
            label: { is: "text", label: "Etichetă" },
            message: { is: "text", label: "Mesaj", _t: false },
        },
        renderer: "Button",
    },
    date: {
	renderer: "Date"
    },
    price: {
        label: "Pret",
        is: "record",
        classes: ["price"],
        keys: {
            amount: { is: "number", decimals: 2, label: "Suma" },
            currency: { is: "ref", ref: "currency", cache: "code,name", label: "Moneda" },
        },
        editor: { nocollapse: true, inline: true, margin: "dense" },
        _op: {
            plus: "priceAdd",
            times: "priceTimes",
        },
    },
    unitPrice: {
        label: "Pret Unitar",
        is: "price",
        icon: "U",
        classes: ["unitPrice"],
        keys: {
            um: {
                is: "ref",
                ref: "um",
                cache: "symbol,factor",
                label: "Unitate",
                editor: { margin: "dense" },
            },
        },
    },
    weight: {
        label: "Greutate",
        is: "map",
        classes: ["weight"],
        keys: {
            amount: { is: "number", decimals: 2, label: "Greutate", editor: { margin: "dense" } },
            um: {
                is: "ref",
                ref: "um",
                cache: "symbol,factor",
                label: "UM",
                editor: { margin: "dense" },
            },
        },
        editor: { nocollapse: true, inline: true, margin: "dense" },
        _closed: true,
    },
    estWeight: {
        is: "weight",
        icon: "E",
    },
    nutrition: {
        is: "map",
        values: "string",
        label: "Valori nutritionale",
    },

    cart: {
        label: "Coș de cumpărături",
        is: "record",
        classes: ["subscription", "cart", "create"],
        keys: {
            items: { is: "list", items: "cartItem", label: "Produse" },
            total: { is: "price", label: "Suma" },
            estimate: { is: "bool", label: "Estimativa" },
            itemsMap: {
                is: "map",
                _calc: true,
                _value: [
                    "zip",
                    ["map", ["f", "parent.items"], ["f", "item.id"]],
                    ["f", "parent.items"],
                ],
                _e: {
                    _value: {
                        _type: "op",
                        1: { _type: "op", 1: { _type: "op" }, 2: { _type: "op" } },
                        2: { _type: "op" },
                    },
                },
            },
        },
        _persist: "sessionStorage",
    },
    openCart: {
        label: "Deschidere coș",
        is: "bool",
        classes: ["subscription"],
    },
    cartItem: {
        label: "Produs în Coș",
        is: "record",
        classes: ["create"],
        keys: {
            id: { is: "string", label: "ID" },
            name: { is: "string", label: "Nume" },
            img: { is: "img", label: "Imagine" },
            q: { is: "number", label: "Cantitate" },
            weight: { is: "weight" },
            price: { is: "price" },
            value: { is: "price", label: "Valoare" },
        },
    },
    addToCart: {
        is: "cartItem",
        label: "Adaugă în coș",
        classes: ["subscription"],
    },
    removeFromCart: {
        is: "string",
        label: "Elimină din coș",
        classes: ["subscription"],
    },
    openMenu: {
        is: "bool",
        label: "Deschide meniu",
    },
    openURL: {
        is: "string",
        label: "Deschide URL",
    },
    slider: {
        label: "Slider",
        icon: "filled/view_list",
        is: "map",
        isBlock: true,
        classes: ["widget"],
        keys: {
            timeout: { is: "number", label: "Interval" },
        },
        renderer: "Slider",
        _contextFun: "sliderContext",
    },
    order: {
        is: "record",
        label: "Comandă",
        keys: {
            items: { is: "list", items: "orderItem", label: "Produse" },
            total: { is: "price", label: "Suma" },
            customer: { is: "customer", label: "Cumpărător" },
        },
    },
    orderItem: {
        is: "cartItem",
        label: "Linie comandă",
    },
    email: {
        is: "string",
        label: "E-mail",
    },
    customer: {
        is: "record",
        label: "Cumpărător",
        keys: {
            name: { is: "string", label: "Nume" },
            email: { is: "email", label: "E-mail" },
            phone: { is: "string", label: "Telefon" },
            address: { is: "string", label: "Adresa" },
        },
        _persist: "localStorage",
    },
    card: {
        is: "record",
        label: "Card",
        classes: ["widget"],
        keys: {
            html: { is: "html", label: "Text", editor: { nocollapse: true } },
            img: { is: "img", label: "Imagine" },
        },
        editor: { nocollapse: true },
    },
}
export default types
