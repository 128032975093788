import React from "react"
import useUnmounted from "lib/hooks/useUnmounted"
import useSubscription, { publish } from "../hooks/useSubscription"
import reducer from "./stateReducer"
import { $keyOrder } from "lib/entity/symbols"
import { ifPromise, ifPromiseArray } from "lib/entity/util"
import Type from "../entity/type"
import Data from "../entity/data"
import calc, { getDeps } from "../entity/calc"
import actionMap from "./actions"
import subscriptionMap from "../entity/subscriptions"
//import useRerender from "./useRerender"
/*
const getOpDeps = op => {
    if (!op || !Array.isArray(op)) return []
    switch (op[0]) {
        case "f": {
            const toks = op?.[1]?.split(":")?.[0]?.split(".")
            let dep = toks?.[0]
            if (dep === "state" && toks.length > 1) dep = `state.${toks[1]}`
            if (dep) return [dep]
            return []
        }
        case "get": {
            const toks = op?.[1]?.split(":")?.[0]?.split(".")
            let dep = toks?.[0]
            if (dep === "state" && toks.length > 1) dep = `state.${toks[1]}`
            if (dep) return [dep, ...getOpDeps(op[2])]
            return getOpDeps(op[2])
        }
        default:
            return op.slice(1).reduce((acc, arg) => [...acc, ...getOpDeps(arg)], [])
    }
}*/

const useElementState = props => {
    //useRerender(props)
    const unmounted = useUnmounted()
    const stateInfo = React.useMemo(
        () => Type.getType(props.info.layout.state, { is: "map", values: "exprVal" }),
        [props.info.layout.state]
    )
    const subscriptions = useSubscription(props.info.layout.subscriptions)
    const subscriptionsMap = React.useMemo(
        () =>
            props.info.layout.subscriptions?.reduce(
                (acc, s, i) => ({ ...acc, [s]: subscriptions[i] }),
                {}
            ),
        [subscriptions, props.info.layout.subscriptions]
    )
    const subscriptionsInfo = React.useMemo(
        () => props.info.layout.subscriptions?.reduce((acc, s) => ({ ...acc, [s]: s }), {}),
        [props.info.layout.subscriptions]
    )
    //if (subscriptions.length > 0)
    //    console.log("SUB", props.info?.layout?.element?.name, subscriptions, subscriptionsMap)
    const persistKeys = React.useRef()
    const publishKeys = React.useRef()
    const opKeys = React.useRef()
    const actionKeys = React.useRef()
    const initState = React.useCallback(
        props => {
            const context = {
                ...(props.info.context ?? {}),
                ...(subscriptionsMap ?? {}),
                _e: {
                    ...(props.info.context?._e ?? {}),
                    ...(subscriptionsInfo ?? {}),
                },
            }
            //console.log("SUB INITSTATE", props.info?.layout?.element?.name, context)
            const newInfo = { ...props.info, context }
            //const stateInfo = Type.getType(info.layout.state, { is: "map", values: "exprVal" })
            return stateInfo[$keyOrder].reduce(
                (acc, key) => {
                    const keyInfo = Type.getKeyType(key, props.info.layout.state, stateInfo)
                    if (keyInfo.classes.includes("subscription"))
                        publishKeys.current = { ...(publishKeys.current ?? {}), [key]: undefined }
                    if (keyInfo._persist) {
                        try {
                            const v =
                                typeof window !== "undefined"
                                    ? window[keyInfo._persist].getItem(key)
                                    : null
                            //console.log(key, v)
                            const value = v ? JSON.parse(v) : undefined
                            persistKeys.current = {
                                ...(persistKeys.current ?? {}),
                                [key]: value,
                            }
                            return {
                                ...acc,
                                [key]: value,
                            }
                        } catch (error) {
                            // Return default value if JSON parsing fails
                            persistKeys.current = {
                                ...(persistKeys.current ?? {}),
                                [key]: undefined,
                            }
                            console.log(error)
                            //return initialValue
                        }
                    }
                    const realType = Type.realType(keyInfo)
                    switch (realType) {
                        case "op": {
                            const deps = getDeps({ value: props.info.layout.state[key] })
                            opKeys.current = { ...(opKeys.current ?? {}), [key]: deps }
                            const value = calc(
                                Data.getValue(props.info.layout.state, key, {
                                    parentType: stateInfo,
                                }),
                                //props.info.layout.state?._e?.[key] ?? "exprVal"
                                //),
                                newInfo.context
                            )
                            return {
                                ...acc,
                                [key]: value.value,
                                _e: {
                                    ...acc._e,
                                    [key]: value._e.value,
                                },
                            }
                        }
                        case "action":
                        case "actionList": {
                            actionKeys.current = [...(actionKeys.current ?? []), key]
                            return acc
                        }
                        default:
                            return acc
                    }
                },
                {
                    ...(props.state ?? {}),
                    ...(props.info.layout.state ?? {}),
                    _e: {
                        ...(props.state?._e ?? {}),
                        ...(props.info.layout.state?._e ?? {}),
                    },
                }
            )
        },
        [stateInfo, subscriptionsInfo, subscriptionsMap]
    )
    const [initialState, localDispatch] = React.useReducer(reducer, props, initState)
    /*if (Object.keys(initialState).includes("cart"))
        console.log("INITIALSTATE", props.info?.layout?.element?.name, initialState, props)*/
    const waitFor = React.useCallback(
        async p => {
            const newState = await p
            if (unmounted.current) return
            //console.log("JOIN1")
            localDispatch({ type: "JOIN", newState })
        },
        [unmounted]
    )

    const init = React.useRef()
    const localState = React.useMemo(() => {
        if (init.current) return initialState
        init.current = true
        const keys = Object.keys(initialState)
        const state = ifPromiseArray(Object.values(initialState), values =>
            keys.reduce((acc, key, i) => ({ ...acc, [key]: values[i] }), {})
        )
        if (state instanceof Promise) {
            waitFor(state)
            return null
        }
        return state
    }, [waitFor, initialState])
    //console.log("STATE2", props.info?.layout?.element?.name, localState)
    //if(localState===null) return null
    const context = React.useMemo(
        () => ({
            ...(props.info.context ?? {}),
            ...(subscriptionsMap ?? {}),
            state: localState,
            _e: {
                ...(props.info.context?._e ?? {}),
                ...(subscriptionsInfo ?? {}),
                state: { is: "map", values: "exprVal" },
            },
        }),
        [props.info.context, localState, subscriptionsMap, subscriptionsInfo]
    )
    const depsRef = React.useRef()
    const state = React.useMemo(() => {
        if (!localState) return localState
        let state = localState
        if (opKeys.current) {
            //console.log("OPKEYS", opKeys.current)
            state = Object.keys(opKeys.current).reduce((acc, key) => {
                const deps = opKeys.current[key]
                //console.log("CHECK", key, deps, depsRef.current, context)
                const changed = deps.reduce((acc, dep) => {
                    if (acc) return acc
                    const toks = dep.split(".")
                    if (toks.length === 1) {
                        const ret = context[dep] !== depsRef.current?.[dep]
                        //console.log("CH", props.info?.layout?.element?.name, dep, ret)
                        return ret
                    }
                    const ret = context.state?.[toks[1]] !== depsRef.current?.state?.[toks[1]]
                    return ret
                }, false)
                if (!changed) return acc

                const value = calc(
                    Data.getValue(props.info.layout.state, key, { parentType: stateInfo }),
                    context
                )
                /*console.log(
                    "CHANGED, recalc",
                    props.info?.layout?.element?.name,
                    key,
                    Data.getValue(props.info.layout.state, key, { parentType: stateInfo }),
                    context,
                    value
                )*/
                return {
                    ...acc,
                    [key]: value.value,
                    _e: {
                        ...acc._e,
                        [key]: value._e.value,
                    },
                }
            }, state)
            if (state !== localState) {
                //console.log("JOIN2", state, localState)
                localDispatch({ type: "JOIN", newState: state })
            }
        }
        depsRef.current = context
        if (!actionKeys.current) return state
        const newInfo = { ...props.info, context }
        state = actionKeys.current.reduce((acc, key) => {
            const keyInfo = Type.getKeyType(key, props.info.layout.state[key], stateInfo)
            const realType = Type.realType(keyInfo)

            return {
                ...acc,
                [key]: props.info.getCb(
                    props.info.layout.state[key],
                    keyInfo,
                    realType,
                    newInfo,
                    localDispatch
                ),
                _e: { ...acc._e, [key]: "fun" },
            }
        }, state)

        return state
        //return [null, null]
    }, [props.info, context, localState, stateInfo])
    /*if (state.cartItem)
        console.log(
            "STATE",
            props.info?.layout?.element?.name,
            state,
            props,
            subscriptions,
            subscriptionsMap,
            opKeys
        )*/
    /*React.useEffect(() => {
        if (state === initialState) return
        //localDispatch({ type: "JOIN", newState: state })
        console.log(state, initialState)
    }, [state, initialState])*/
    const info = React.useMemo(
        () => ({
            ...props.info,
            context: {
                ...context,
                state,
                _e: {
                    ...context._e,
                    state: { is: "map", values: "exprVal" },
                },
            },
        }),
        [props.info, context, state]
    )

    //console.log("STATE", info?.layout?.element?.name, state)
    React.useEffect(() => {
        if (!state || !persistKeys.current) return
        //console.log("PERSIST", state, stateInfo)
        Object.keys(persistKeys.current).forEach(key => {
            //console.log(key)
            //console.log(sessionKeys.current[key])
            //console.log(state)
            if (persistKeys.current[key] === state?.[key]) return
            const keyInfo = Type.getKeyType(key, props.info.layout.state, stateInfo)
            if (typeof state?.[key] !== "undefined") {
                //console.log("PERSIST KEY", key, JSON.stringify(state[key]))
                window[keyInfo._persist].setItem(key, JSON.stringify(state[key]))
            }
        })
    }, [state, props.info.layout.state, stateInfo])
    React.useEffect(() => {
        if (!publishKeys.current || !state) return
        Object.keys(publishKeys.current).forEach(key => {
            if (publishKeys.current[key] === state?.[key]) return
            publishKeys.current[key] = state?.[key]
            const keyInfo = Type.getKeyType(key, info.layout?.state, stateInfo)
            const realType = Type.realType(keyInfo)
            //console.log("PUBLISH", info?.layout?.element?.name, realType, state[key])
            publish(realType, state[key])
        })
    }, [state, info, stateInfo])

    const dispatch = React.useCallback(
        action => {
            //console.log("DISPATCH", action)
            switch (action.type) {
                case "CHANGE": {
                    const key = action.field?.split(".")?.[0]
                    const keyInfo = Type.getKeyType(key, info.layout.state, stateInfo)
                    //console.log(key, keyInfo, info.layout?.state, state)
                    if (keyInfo) {
                        localDispatch(action)
                        return
                    } else {
                        //localDispatch(action)
                        props.dispatch(action)
                    }
                    return
                }
                case "JOIN": {
                    return localDispatch(action)
                }
                default:
                    return localDispatch(action)
            }
        },
        [props, localDispatch, stateInfo, info.layout.state]
    )
    React.useEffect(() => {
        if (!props.state) return
        //console.log("new props.state", info?.layout?.element?.name, props.state)
        //console.log("JOIN3")
        localDispatch({ type: "JOIN", newState: props.state })
    }, [props.state, info?.layout?.element?.name])

    const messages = React.useMemo(
        () => info.layout.handlers?.map(h => h.message) ?? [],
        [info.layout.handlers]
    )
    const handler = React.useCallback(
        (topic, value) => {
            //console.log("HANDLER", info?.layout?.element?.name, topic, value, info.layout.handlers)
            let index = -1
            const handler = info.layout?.handlers?.filter((h, i) => {
                if (h.message === topic) {
                    index = i
                    return true
                }
            })?.[0]?.handler
            if (!handler) return
            const handlerInfo = Type.getKeyType(
                `${index}.handler`,
                info.layout.handlers,
                info.layout.handlersInfo
            )
            //console.log(index, handler, handlerInfo)
            /*const messageType = Type.typeFromTypeDef({
                is: topic && subscriptionMap[topic] ? subscriptionMap[topic] : "t",
                label: "Mesaj",
            })*/
            let hinfo = {
                ...info,
                context: {
                    ...(info.context ?? {}),
                    arg: value,
                    _e: {
                        ...(info.context?._e ?? {}),
                        arg: {
                            is: topic && subscriptionMap[topic] ? subscriptionMap[topic] : "t",
                            label: "Mesaj",
                        },
                    },
                },
            }
            const multiple = handler.length > 1
            const execRec = i => {
                if (i >= handler.length) return
                const actionName = handler[i][0]
                const action = actionMap[actionName]
                if (!action) return
                const vinfo = Type.getKeyType(`${i}`, handler, handlerInfo)
                //console.log(vinfo, keyInfo, i)
                return ifPromise(
                    action(handler[i], vinfo, value, hinfo, localDispatch, multiple),
                    ret => {
                        if (multiple) hinfo = ret
                        //console.log(handler[i][0], value, hinfo, ret)
                        return execRec(i + 1)
                    }
                )
            }
            execRec(0)
        },
        [info, localDispatch]
    )
    useSubscription(messages, handler)
    //console.log(info?.layout?.element?.name, info, state)
    return [info, state, dispatch]
}

export default useElementState
