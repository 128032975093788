import React, { useMemo } from "react"
import { Entity, useQuery, useUnmounted } from "lib"
//import { getDataValue } from "./Element"
import calc from "../entity/calc"
import { $lineage } from "../entity/symbols"
/*let cache = {}
export const clearElements = () => {
    cache = {}
}*/

const elementsQuery = { collection: "site", query: { type: "element" }, projection: { name: 1 } }

export const useElement = (info, args) => {
    const [results] = useQuery(elementsQuery)
    const elements = useMemo(
        () => results?.reduce((acc, item) => ({ ...acc, [item.name]: item.name }), {}),
        [results]
    )

    //console.log(args, info)
    const elementQuery = useMemo(() => {
        if (!info) return null
        if (!args?.element && (info?.typeInfo?.renderer || info.typeInfo?._layout)) return null
        if (!elements) return undefined
        //console.log(info)
        const name = args?.element
            ? elements[args.element]
            : elements[capitalize(info.typeName)] ??
              info?.typeInfo?.[$lineage]?.reduce(
                  (acc, typeName) => acc ?? elements[capitalize(typeName)],
                  undefined
              )
        //console.log(args, name, elements)
        if (!name) return null
        return { collection: "site", query: { type: "element", name } }
    }, [elements, info, args])
    //console.log(elementQuery)
    const [res] = useQuery(elementQuery)
    //console.log(args, res, status)
    if (!info) return undefined
    return res?.[0] ?? (elements ? (elementQuery === null ? null : undefined) : undefined)
}

const capitalize = str =>
    typeof str === "string" && str.length > 0 ? str.charAt(0).toUpperCase() + str.slice(1) : str
const useLayout = (info, args, state) => {
    //console.log("USE LAYOUT", info, args, state)
    const [renderID, triggerRender] = React.useState(0)
    const unmounted = useUnmounted()
    const waitFor = React.useCallback(
        async p => {
            Promise.all(p).then(() => {
                if (unmounted.current) return
                triggerRender(v => (v + 1) % 100)
            })
        },
        [unmounted]
    )
    const element = useElement(info, args)
    //console.log("Use Layout element", element?.name, element, args)
    return useMemo(() => {
        if (!args.element && info?.typeInfo?._layout) {
            //console.log("LAYOUT", info, args)
            const context = Entity.get(info.value, "_context", { parentType: info.typeInfo })
            return {
                root: info?.typeInfo?._layout,
                rootInfo: Entity.getKeyType("_layout", info.value, info.typeInfo),
                //element,
                //display: display?.[0]?.name,
                //path: `displays.${display[1]}.layout`,

                subscriptions: context?.subscriptions,
                state: context?.state,
                stateInfo: Entity.getKeyType(`_context.state`, info.value, info.typeInfo),
                handlers: context?.handlers,
                handlersInfo: Entity.getKeyType(`_context.handlers`, info.value, info.typeInfo),
            }
        }
        if (!element) {
            return element
        }
        const displayName = info.args?.display ?? info.attrs?.display
        let promises = []
        const display = element?.displays
            ?.map((d, i) => [d, i])
            ?.filter(([d, i]) => {
                if (displayName) {
                    //console.log(displayName)
                    return d?.name === displayName
                }
                if (!d?.guard) return true
                //const t = Entity.getKeyType(`displays.${i}.guard`, element)
                //console.log(element, d, i, Entity.getValue(element, `displays.${i}.guard`))
                const ret = calc(Entity.getValue(element, `displays.${i}.guard`), {
                    ...(info.context ?? {}),
                    state,
                })
                //console.log("GUARD:", d.guard, info, state, ret)
                if (ret instanceof Promise) {
                    promises.push(ret)
                    return true
                }
                return ret.value
            })?.[0]
        //const layout = display?.layout
        //const layoutInfo = Entity.getKeyType("displays.0.layout", element)
        if (promises.length > 0) {
            waitFor(promises)
            return undefined
        }
        if (!display) return display //return { ...info, layout: undefined }

        return {
            root: display?.[0]?.layout,
            rootInfo: Entity.getKeyType(`displays.${display[1]}.layout`, element),
            element,
            display: display?.[0]?.name,
            path: `displays.${display[1]}.layout`,

            subscriptions: display?.[0]?.context?.subscriptions,
            state: display?.[0]?.context?.state,
            stateInfo: Entity.getKeyType(`displays.${display[1]}.context.state`, element),
            handlers: display?.[0]?.context?.handlers,
            handlersInfo: Entity.getKeyType(`displays.${display[1]}.context.handlers`, element),
        }
    }, [state, waitFor, renderID, info, element]) // eslint-disable-line react-hooks/exhaustive-deps
}
export default useLayout
