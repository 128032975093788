import { useRef, useMemo } from "react"
import { useSubscriptionProvider } from "../hooks"

const useAdmin = (info, oldInfo) => {
    const setFieldAdmin = useRef(useSubscriptionProvider("fieldAdmin"))
    const mouseOver = useRef(false)
    useMemo(() => {
        if (!info?.user) return

        info.path = info ? [...(oldInfo?.path ?? []), info] : oldInfo?.path
        if (!info.wrapperAttrs) info.wrapperAttrs = {}
        info.wrapperAttrs.onMouseOver = () => {
            //console.log("MOUSE OVER")
            if (mouseOver.current) return
            mouseOver.current = true
            setFieldAdmin.current({ mouse: "enter", fieldLine: info.path })
        }

        info.wrapperAttrs.onMouseLeave = () => {
            //console.log("MOUSE OUT")
            window.requestAnimationFrame(() => {
                mouseOver.current = false
                setFieldAdmin.current({ mouse: "leave", fieldLine: info.path })
            })
        }
        //}
    }, [info, oldInfo])
}

export default useAdmin
