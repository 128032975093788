import React from "react"
//import { publish } from "../hooks/useSubscription"
import actionMap from "./actions"
import Type from "../entity/type"
import { ifPromise } from "../entity/util"

export const nameAttr = (type, name) =>
    type +
    "-" +
    name
        .split(/([A-Z])/)
        .filter(t => !!t)
        .map((t, i) => (i > 0 && /[A-Z]/.test(t) ? "-" + t.toLowerCase() : t.toLowerCase()))
        .join("")
const permitted = ["id", "className", "style"]
const attrFilter = attr => permitted.includes(attr) || attr.indexOf("-") > 0

// wrapper attrs
const useAttributes = (info, args) => {
    const [localAttr, setLocalAttr] = React.useState({})

    const getCb = React.useCallback(
        (value, keyInfo, unionType, info, dispatch) =>
            (...args) => {
                //console.log(value, args, info, actionMap)
                const values = unionType === "action" ? [value] : value
                let newInfo = {
                    ...info,
                    context: {
                        ...(info.context ?? {}),
                        args,
                        _e: { ...(info.context?._e ?? {}), args: "args" },
                    },
                }
                //let i = 0
                const multiple = values.length > 1
                const exec = i => {
                    const v = values[i]
                    const actionName = v[0]
                    const action = actionMap[actionName]
                    if (!action) return
                    const vinfo =
                        unionType === "action" ? keyInfo : Type.getKeyType(`${i}`, value, keyInfo)
                    //console.log(vinfo, keyInfo, i)
                    ifPromise(action(v, vinfo, args, newInfo, dispatch, multiple), ret => {
                        if (multiple) newInfo = ret
                        if (i === values.length - 1) return
                        exec(i + 1)
                    })
                }
                exec(0)
            },
        []
    )
    //let attrs = { ...(args.attrs ?? {}), ...admin }
    return React.useMemo(() => {
        if (!info) {
            //console.log("NO INFO", args)
            return [null, null]
        }
        //...args.attrs,
        let attrs = {
            ...Object.keys(args.attrs ?? {})
                .filter(attrFilter)
                .reduce((acc, key) => ({ ...acc, [key]: args.attrs[key] }), {}),
            ...localAttr,
        }
        //console.log("ARGS", args)
        if (args?.element) {
            //console.log(args)
            attrs[nameAttr("elem", args.element)] = ""
        }
        if (args?.fieldName) {
            attrs[nameAttr("field", info.fieldName)] = ""
            attrs[nameAttr("type", info.typeName)] = ""
            if (info.typeInfo.id) attrs["id"] = info.typeInfo.id
            if (info.typeInfo._class) attrs["className"] = info.typeInfo._class

            if (info.typeName === "block") {
                let blockType = []
                if (info.typeInfo._o) blockType = [...blockType, "horizontal"]
                if (info.typeInfo.f) blockType = [...blockType, "fullfixed"]
                if (info.typeInfo.p || info.typeInfo.bg) blockType = [...blockType, "proportional"]
                if (blockType.length > 0) attrs["block-type"] = blockType.join(" ")
            }
        } else {
            if (args?.entity) {
                attrs[nameAttr("type", info.typeName)] = ""
            }
        }

        //if (args?.attrs?.id) attrs["id"] = args.attrs.id
        //if (args?.attrs?.className) attrs["className"] = args.attrs.className

        //attrs.style = { ...(attrs.style ?? {}), "--index": args?.attrs?._index ?? 0 }
        return [getCb, setLocalAttr, attrs]
    }, [getCb, args, info, localAttr])
}

export default useAttributes
