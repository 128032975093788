import React from "react"

const Typography = ({ domRef, info, state, dispatch, content, className, _e, ...args }) => {
    const text = content ?? ""
    const classes = className ? `typography ${className}` : "typography"
    let n = 0
    return (
        <div className={classes} {...args}>
            {text.split(" ").map((word, i) => {
                return (
                    <span key={i} style={{ "--word": i }} className={`word word-${i}`}>
                        {word.split("").map((letter, j) => {
                            const style = {
                                "--let": n,
                                "--wlet": j,
                            }
                            n += 1
                            return (
                                <span key={j} className={`let wlet-${j} let-${n}`} style={style}>
                                    {letter}
                                </span>
                            )
                        })}
                    </span>
                )
            })}
        </div>
    )
}
export default Typography
