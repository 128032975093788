import React from "react"
import { renderVideo } from "../../util"

const Video = ({ domRef, info, subs, path, state, dispatch, ...args }) => {
    //console.log("DATE", info, args)
    //console.log("VID", info, args)
    return (
	<div ref={domRef} type-video="" {...args}>
	    {typeof info.value === "string" ? renderVideo(info.value) : "video"}
	</div>
    )
    //return info.value
    //return <FaIcon icon={info.value} {...args} />
    //return <LazyImage {...args} />
}
export default Video
